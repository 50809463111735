import React from 'react'
import { IoCheckmarkCircle, IoBuild, IoFileTrayFull } from 'react-icons/io5'

const ROADMAP_ITEMS = [
  {
    number: '01',
    title: 'Byggjum grunninn',
    subtitle: 'Foundation',
    timeline: 'Q2 - Q4 2022',
    active: true,
    description:
      'Bootstrap all systems. This phase aims to get the groundwork for Poki done. One of the biggest challenges here is to implement a robust infrastructure which is capable of transforming a users wallet history into usable timeseries data on demand. Furthermore, the system needs to be able to keep that history in sync over time. By the end of this phase, v1 of the Poki Browser App as well as the Mobile Apps should be available to the public.',
    milestones: [
      {
        title: 'Wallet History Backend',
        status: 'done',
      },
      {
        title: 'Cardano NFTs Price History Database',
        status: 'done',
      },
      {
        title: 'Poki Web / Browser',
        status: 'done',
      },
      {
        title: 'Mobile App Beta',
        status: 'done',
      },
      {
        title: 'Migrate / Rebrand CNFT Alerts to Poki',
        status: 'done',
      },
      {
        title: 'Mobile App v1',
        status: 'done',
      },
      {
        title: 'Mint Poki Lifetime Pass',
        status: 'done',
      },
    ],
  },
  {
    number: '02',
    title: 'Ofurhraði',
    subtitle: 'Supercharge',
    timeline: 'Q1 - Q2 2023',
    description:
      'The acceleration phase. In this phase the theme will be to supercharge the features of Poki. The result will be a comprehensive Cardano Portfolio App that not only helps users to keep track of all their Cardano assets, but also actively informs them whenever interesting events around their portfolio or in the ecosystem occur.',
    milestones: [
      {
        title: 'Add CNFT Alerts / Live Market Data to Mobile',
        status: 'planned',
      },
      {
        title: 'Support for Tokens / Coins',
        status: 'done',
      },
      {
        title: 'Mobile Push Notifications',
        status: 'planned',
      },
      {
        title: 'Support manually adding NFTs / FTs',
        status: 'planned',
      },
      {
        title: 'Support for staked Assets',
        status: 'planned',
      },
      {
        title: 'Support assets listed on jpg.store',
        status: 'done',
      },
      {
        title: 'Profit / Loss Calculations',
        status: 'planned',
      },
      {
        title: 'Support for Trait Floors',
        status: 'planned',
      },
    ],
  },
  {
    number: '03',
    title: 'Kvörðun',
    subtitle: 'Scaling',
    timeline: 'Q3 2023',
    description:
      'Make the Poki infrastructure future-proof. The groundwork has been done, the app has exciting features and a healthy userbase. Every single piece of the tech infrastructure will get revisited. The main goal here is to ensure Poki will be able to easily handle an increasing dataload coming from new users and a thriving Cardano ecosystem.',
    milestones: [
      {
        title: 'Optimise Blockchain data fetching',
        status: 'planned',
      },
      {
        title: 'Engineer fallback for token price history',
        status: 'planned',
      },
      {
        title: 'Optimise Poki datastructures / queries',
        status: 'planned',
      },
      {
        title: 'Support for yearly / monthly memberships',
        status: 'planned',
      },
    ],
  },
]

export const Roadmap: React.FC = () => {
  const iconForMilestone = (status: string) => {
    if (status === 'done') {
      return <IoCheckmarkCircle className="text-green-300" />
    } else if (status === 'in_progress') {
      return <IoBuild className="text-blue-300" />
    } else if (status === 'planned') {
      return <IoFileTrayFull className="text-yellow-300" />
    }
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 xl:w-2/3 3xl:w-1/2">
          <p className="text-6xl font-bold text-neutral-200">Poki Roadmap</p>

          {ROADMAP_ITEMS.map((item, index) => {
            return (
              <div
                className={`flex flex-col pb-20 mt-12 ${
                  index !== ROADMAP_ITEMS.length - 1
                    ? 'border-neutral-800 border-b '
                    : ''
                }`}
                key={`roadmap-${index}`}>
                {index % 2 === 0 ? (
                  <div className="flex flex-col pb-5 md:flex-row md:justify-between">
                    <div className="flex flex-col">
                      <p className="text-xl font-bold text-neutral-400">
                        {item.number}
                      </p>
                      <div className="flex flex-row gap-3">
                        <p className="self-center text-4xl font-semibold text-neutral-200">
                          {item.title}
                        </p>
                        <p className="self-center pt-2 text-lg text-neutral-200">
                          |
                        </p>
                        <p className="self-center pt-3 text-sm text-purple-600">
                          {item.subtitle}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`md:self-end self-start mt-3 md:mt-0 px-5 py-2 ${
                        item.active
                          ? 'bg-neutral-700 animate-pulse'
                          : 'bg-neutral-700'
                      } rounded-lg`}>
                      <p className="text-base font-semibold text-white ">
                        {item.timeline}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row justify-between pb-5">
                    <div
                      className={`self-end w-44 px-5 hidden md:flex py-2 ${
                        item.active
                          ? 'bg-neutral-700 animate-pulse'
                          : 'bg-neutral-700'
                      } rounded-lg justify-center`}>
                      <p className="text-base font-semibold text-center text-white ">
                        {item.timeline}
                      </p>
                    </div>
                    <div className="flex flex-col w-full">
                      <p className="self-end text-xl font-bold text-neutral-400">
                        {item.number}
                      </p>

                      <div className="flex flex-row self-end gap-3">
                        <p className="self-center pt-3 text-sm text-purple-600">
                          {item.subtitle}
                        </p>
                        <p className="self-center pt-2 text-lg text-neutral-200">
                          |
                        </p>
                        <p className="self-center text-4xl font-semibold text-neutral-200">
                          {item.title}
                        </p>
                      </div>

                      <div
                        className={`self-end px-5 md:hidden flex py-2 mt-3 md:mt-0 ${
                          item.active
                            ? 'bg-neutral-700 animate-pulse'
                            : 'bg-neutral-700'
                        } rounded-lg`}>
                        <p className="text-base font-semibold text-white ">
                          {item.timeline}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <p className="mt-4 text-sm text-neutral-200">
                  {item.description}
                </p>

                <div className="mt-8">
                  <p className="text-lg font-bold text-neutral-200">
                    Milestones
                  </p>

                  <div className="flex flex-wrap gap-5 mt-6">
                    {item.milestones.map((milestone, index) => {
                      return (
                        <div
                          className="justify-center px-4 py-3 rounded-lg bg-neutral-800"
                          key={`milestone-${index}`}>
                          <div className="flex flex-row gap-1 truncate">
                            {iconForMilestone(milestone.status)}
                            <p className="text-xs font-bold text-center text-neutral-200">
                              {milestone.title}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}

          <div className="flex flex-col justify-center pt-10 mt-32 border-t border-neutral-800">
            <div className="flex flex-row self-center gap-4">
              <p className="self-center text-xs font-bold text-neutral-200">
                Powered by
              </p>
              <img src="/images/cnft_alerts_logo.jpg" className="w-16" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
