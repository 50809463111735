import React, { useState } from 'react'
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from '../../route-paths'
import { PricingBoxes } from '../../components/PricingBoxes'

const IMAGES = [
  '/images/portfolio_tab.png',
  '/images/analytics_tab.png',
  '/images/analytics_tab_2.png',
  '/images/collection_screen.png',
]

export const Root: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const navigate = useNavigate()

  const launchApp = () => navigate(RoutePaths.Portfolio)

  const onClickForward = () =>
    setCurrentIndex((currentIndex + 1) % IMAGES.length)
  const onClickBack = () => {
    if (currentIndex === 0) {
      setCurrentIndex(IMAGES.length - 1)
    } else {
      setCurrentIndex(currentIndex - 1)
    }
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-20 pb-20 md:pt-32 xl:w-2/3">
          <div className="flex flex-col self-center w-2/3 md:w-1/2 xl:w-2/3 border-neutral-800">
            <img className="self-center" src="/images/logo.png" />
          </div>

          <div className="self-center w-full"></div>

          <div className="flex flex-col justify-between gap-10 pb-12 mt-10 md:border-b md:mt-20 md:flex-row lg:mt-32 md:border-neutral-800">
            <div className="flex flex-col self-center mt-12 md:self-start">
              <p className="text-5xl font-bold text-neutral-400">
                Mobile Cardano
              </p>
              <p className="text-5xl font-bold text-neutral-200">
                Asset Portfolio
              </p>

              <p className="mt-2 font-bold text text-neutral-400">
                Track your NFTs, Tokens and ADA.
              </p>

              <p className="mt-12 text-sm text-neutral-200">
                Available for iOS, Android and in the Browser.
              </p>

              <div className="flex-col hidden md:flex">
                <button
                  className="py-4 mt-4 text-sm font-bold text-white uppercase bg-purple-700 w-72 rounded-xl hover:bg-purple-600"
                  onClick={launchApp}>
                  Launch App
                </button>

                <div className="flex flex-row mt-4">
                  <a
                    href="https://apps.apple.com/app/poki-cardano-nft-portfolio/id6444388939"
                    target="_blank"
                    className="self-center"
                    rel="noreferrer">
                    <img
                      className="w-32 hover:cursor-pointer"
                      src={'/images/apple_app_store.svg'}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.knobsen.poki"
                    target="_blank"
                    className="self-center"
                    rel="noreferrer">
                    <img
                      className="w-40 hover:cursor-pointer"
                      src={'/images/google_play_store.png'}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-center select-none">
              <div className="self-center mr-2 text-neutral-400 hover:text-neutral-200 hover:cursor-pointer">
                <MdOutlineArrowBackIos size={30} onClick={onClickBack} />
              </div>
              <div className="self-center w-2/3 md:w-72">
                <img src={IMAGES[currentIndex]} />
              </div>
              <div className="self-center ml-2 text-neutral-400 hover:text-neutral-200 hover:cursor-pointer">
                <MdOutlineArrowForwardIos size={30} onClick={onClickForward} />
              </div>
            </div>
          </div>

          <div className="flex flex-col pb-12 border-b md:hidden border-neutral-800">
            <button
              className="py-4 mt-20 text-sm font-bold text-white uppercase bg-purple-600 rounded-xl hover:bg-purple-500"
              onClick={launchApp}>
              Launch App
            </button>

            <div className="flex flex-row self-center mt-4">
              <a
                href="https://apps.apple.com/app/poki-cardano-nft-portfolio/id6444388939"
                target="_blank"
                className="self-center"
                rel="noreferrer">
                <img
                  className="w-32 hover:cursor-pointer"
                  src={'/images/apple_app_store.svg'}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.knobsen.poki"
                target="_blank"
                className="self-center"
                rel="noreferrer">
                <img
                  className="w-40 hover:cursor-pointer"
                  src={'/images/google_play_store.png'}
                />
              </a>
            </div>
          </div>

          <p className="self-center text-4xl font-bold mt-14 text-neutral-400 md:self-start">
            Our Plans and Pricing
          </p>
          <p className="mt-12 md:mt-2 text-neutral-200 text-wide">
            Save valuable time on tracking all your wallets and assets manually.
            Leave it to us and focus on what matters most to you - investing
            into Cardano assets.
          </p>

          <PricingBoxes />

          <div className="flex flex-col justify-center pt-10 mt-32 border-t border-neutral-800">
            <div className="flex flex-row self-center gap-4">
              <p className="self-center text-xs font-bold text-neutral-200">
                Powered by
              </p>
              <img src="/images/cnft_alerts_logo.jpg" className="w-16" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
