import React from 'react'
import { PricingBoxes } from '../../components/PricingBoxes'

export const LifetimePass: React.FC = () => {
  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 xl:w-2/3">
          <p className="text-6xl font-bold text-neutral-200">
            Poki Lifetime Pass
          </p>

          <div className="flex flex-col gap-5 pb-12 mt-12 border-b md:flex-row border-neutral-800">
            <div className="w-full md:w-1/2 2xl:w-2/3">
              <video autoPlay muted={true} loop playsInline>
                <source src="/images/poki_lifetime_pass.mp4" type="video/mp4" />
              </video>
            </div>

            <div className="flex flex-col w-full md:w-1/2 2xl:w-full">
              <p className="text-sm text-neutral-200">
                The Poki Lifetime Pass is a limited supply utility token that
                allows you to unlock the wallet limit on your Portfolio, view
                your Portfolio History and more.
              </p>

              <div className="flex flex-col h-full mt-6">
                <p className="text-lg font-bold text-neutral-400">
                  Circulating Supply
                </p>
                <p className="text-sm text-white">467 Passes.</p>

                <p className="mt-6 text-lg font-bold text-neutral-400">
                  Total Planned / Maximum Supply
                </p>
                <p className="text-sm text-white">1000 Passes.</p>

                <p className="mt-6 text-lg font-bold text-neutral-400">
                  Next Mint Date
                </p>
                <p className="text-sm text-white">
                  February 2023, details TBA.
                </p>
              </div>

              <a
                href="https://www.jpg.store/collection/poki-lifetime-pass"
                target="_blank"
                rel="noreferrer"
                className="py-4 mt-12 text-sm font-semibold text-center bg-yellow-400 rounded-lg hover:bg-yellow-300 px-28">
                Buy on JPG.store
              </a>
            </div>
          </div>

          <PricingBoxes />

          <div className="flex flex-col justify-center pt-10 mt-32 border-t border-neutral-800">
            <div className="flex flex-row self-center gap-4">
              <p className="self-center text-xs font-bold text-neutral-200">
                Powered by
              </p>
              <img src="/images/cnft_alerts_logo.jpg" className="w-16" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
