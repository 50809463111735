import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useUser } from '../../context/UserContext'
import { IoCheckmarkCircle, IoOpenOutline } from 'react-icons/io5'
import useApi, { Account, User } from '../../hooks/useApi'
import { UpgradeModal } from '../../components/UpgradeModal'
import { AddWalletModal } from '../../components/AddWalletModal'
import { RemoveWalletModal } from '../../components/RemoveWalletModal'
import { BounceLoader } from 'react-spinners'

const DISCORD_OAUTH_URL =
  'https://discord.com/api/oauth2/authorize?client_id=1046900278651256902&redirect_uri=https%3A%2F%2Fgetpoki.com%2Fdiscord-oauth&response_type=token&scope=identify'

export const AccountOverview: React.FC = () => {
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false)
  const [showAddWalletModal, setShowAddWalletModal] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [showRemoveWalletModal, setShowRemoveWalletModal] = useState(false)
  const [walletToRemove, setWalletToRemove] = useState({} as Account)
  const [_user, setUser] = useState({} as User)

  const { getUser } = useApi()
  const { user } = useUser()

  useEffect(() => {
    const fetchUser = async () => {
      if (user.id && user.token) {
        try {
          const { user: userFromResponse } = await getUser(user.id, user.token)

          if (userFromResponse) {
            setUser(userFromResponse)
          } else {
            console.log('An unknown error occured')
          }
        } catch (error) {
          console.log('An unknown error occured')
        } finally {
          setLoading(false)
        }
      }
    }

    fetchUser()
  }, [])

  const onAddWallet = () => {
    if (_user.pro) {
      setShowAddWalletModal(true)
    } else {
      setShowUpgradeMessage(true)
    }
  }

  const onRemoveWallet = (walletData: Account) => {
    setWalletToRemove(walletData)
    setShowRemoveWalletModal(true)
  }

  const magicLoginLink = () => {
    if (process.env.REACT_APP_MOBILE_LOGIN_LINK_ACTIVE == 'true') {
      return `pokiportfolio://magicLogin?token=${_user.token}`
    } else {
      return 'Coming soon! Follow us on Twitter & Discord for updates.'
    }
  }

  const onVerifyHolder = () => {
    window.location.assign(DISCORD_OAUTH_URL)
  }

  const onCloseRemoveWalletModal = () => setShowRemoveWalletModal(false)
  const onCloseAddWalletModal = () => setShowAddWalletModal(false)
  const onCloseUpgradeModal = () => setShowUpgradeMessage(false)
  const mainWalletBorderColor = () =>
    _user.pro ? 'border-purple-400' : 'border-orange-400'

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">
            Account Overview
          </p>

          <div className="flex flex-row gap-2 mt-12 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        {showUpgradeMessage && <UpgradeModal onClose={onCloseUpgradeModal} />}

        {showAddWalletModal && (
          <AddWalletModal onClose={onCloseAddWalletModal} />
        )}

        {showRemoveWalletModal && (
          <RemoveWalletModal
            {...walletToRemove}
            onClose={onCloseRemoveWalletModal}
          />
        )}

        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">
            Account Overview
          </p>

          <div className="flex flex-col mt-12">
            <div className="flex flex-col justify-between w-full gap-5 p-6 border rounded-lg md:flex-row border-stone-800">
              <div className="flex flex-col gap-5">
                <div>
                  <p className="text-lg font-bold text-neutral-200">
                    Poki Access Level
                  </p>
                  <p
                    className={`font-bold text-white text-lg ${
                      _user.pro ? 'text-purple-500' : 'text-orange-500'
                    }`}>
                    {_user.pro ? 'Pro' : 'Basic'}
                  </p>
                </div>

                <div>
                  <p className="text-lg font-bold text-neutral-200">
                    Sync your Account with the Mobile App
                  </p>
                  <div className="flex flex-col gap-1 mt-1 text-xs text-neutral-400">
                    <p>
                      <strong>1.</strong> Close the Poki Mobile App. Leave it
                      closed.
                    </p>
                    <p>
                      <strong>2.</strong> Open the System Camera App on your
                      Smartphone.
                    </p>
                    <p>
                      <strong>3.</strong> Scan the QR code from this page. The
                      App will open and you will get logged in.
                    </p>
                    <p>
                      <strong>4.</strong> If that didn&apos;t work, copy & paste
                      the Mobile Login Link from below into your smartphone
                      browser.
                    </p>
                  </div>
                </div>

                <div>
                  <p className="text-lg font-bold text-neutral-200">
                    Mobile Login Link
                  </p>
                  <p className="mt-1 text-xs text-neutral-400">
                    {`https://getpoki.com/magic-login?token=${user.token}`}
                  </p>
                </div>

                {_user.pro && (
                  <div>
                    <p className="text-lg font-bold text-neutral-200">
                      Discord Holder Verification
                    </p>

                    <div className="flex flex-row gap-2">
                      <button
                        className="self-start px-5 py-3 mt-4 bg-purple-700 rounded-lg hover:bg-purple-500"
                        onClick={onVerifyHolder}>
                        <p className="text-xs font-bold text-white">
                          Get Holder Role
                        </p>
                      </button>

                      {_user.discord_id && (
                        <div className="flex flex-row gap-1 mt-4">
                          <IoCheckmarkCircle
                            className="self-center text-green-400"
                            size={22}
                          />
                          <p className="self-center text-xs text-neutral-200">
                            You are verified.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="self-start p-2 bg-white">
                <QRCode value={magicLoginLink()} size={180} />
              </div>
            </div>
          </div>

          <p className="mt-10 text-xl font-bold text-white">Your Wallets</p>

          <div className="grid w-full grid-cols-1 gap-5 mt-4 md:grid-cols-2 2xl:grid-cols-3">
            {_user.accounts &&
              _user.accounts.map((account, index) => {
                return (
                  <div
                    key={`wallet-${index}`}
                    className={`flex flex-col p-3 px-5 pb-5 mb-8 border bg-stone-900
                  rounded-lg ${
                    _user.stake_addr === account.stake_addr
                      ? mainWalletBorderColor()
                      : 'border-stone-800'
                  }`}>
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row justify-between">
                        <p className="text-sm font-bold text-neutral-400">
                          Wallet #{index + 1}
                        </p>
                      </div>
                      <p className={`text-lg font-bold text-white break-words`}>
                        {_user.stake_addr === account.stake_addr
                          ? 'Main Wallet'
                          : account.name}
                      </p>
                      <div className="flex flex-row text-neutral-400 hover:text-neutral-200">
                        <a
                          href={`https://pool.pm/${account.stake_addr}`}
                          target="_blank"
                          rel="noreferrer"
                          className="truncate">
                          {account.stake_addr}
                        </a>
                        <a
                          href={`https://pool.pm/${account.stake_addr}`}
                          target="_blank"
                          rel="noreferrer"
                          className="self-center mb-1 ml-1">
                          <IoOpenOutline size={17} />
                        </a>
                      </div>

                      {_user.stake_addr !== account.stake_addr ? (
                        <button
                          className="self-start px-3 py-3 mt-6 rounded-lg bg-neutral-700 hover:bg-red-500"
                          onClick={() => onRemoveWallet(account)}>
                          <p className="text-xs font-bold text-white">
                            Remove Wallet
                          </p>
                        </button>
                      ) : (
                        <div className="self-start px-3 py-3 mt-6 rounded-lg bg-neutral-800">
                          <p
                            className={`text-xs font-bold ${
                              _user.pro ? 'text-purple-500' : 'text-orange-500'
                            }`}>
                            {_user.pro
                              ? 'Holds Poki Access Pass'
                              : 'No Poki Access Pass'}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>

          <div className="fixed bottom-0 left-0 flex flex-col w-full p-5 border-t bg-neutral-900 border-neutral-800">
            <button
              className="self-center w-full py-3 bg-blue-600 rounded-lg 2xl:w-5/6 hover:bg-blue-500 px-7"
              onClick={onAddWallet}>
              <p className="text-xs font-bold text-white">Add Wallet</p>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
