import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext'
import useApi, { Collection, PortfolioEntity, User } from '../../hooks/useApi'
import { UpgradeModal } from '../../components/UpgradeModal'
import { BounceLoader } from 'react-spinners'

import {
  IoCaretDown,
  IoCaretUp,
  IoInformationCircleOutline,
} from 'react-icons/io5'
import {
  formatNumber,
  intToString,
  numberColor,
  isPositiveDiff,
  numberWithSign,
  ADA_SYMBOL,
  FREE_USER_HISTORY,
  formatDate,
  percentageDiff,
  INTERVALS,
} from '../../util/format'
import { useNavigate } from 'react-router-dom'
import { LineChart } from '../../components/LineChart'
import { RoutePaths } from '../../route-paths'

interface SortEntity {
  [key: string]: string
  value: string
  name: string
  price: string
  asset_count: string
}

type ValueFromChart = number | null
type DateFromChart = string | null

const TOTALS_IN_WALLET = [
  {
    key: 'value_total',
    name: 'Total',
    keyChange: 'total_combined',
    keyChangePercentage: 'percentage_combined',
  },
  {
    key: 'value_nfts',
    name: 'NFTs',
    keyChange: 'total_nfts',
    keyChangePercentage: 'percentage_nfts',
    display: true,
  },
  {
    key: 'value_coins',
    name: 'Tokens',
    keyChange: 'total_coins',
    keyChangePercentage: 'percentage_coins',
    display: true,
    proRequired: false,
  },
  {
    key: 'value_ada',
    name: 'ADA',
    keyChange: 'total_ada',
    keyChangePercentage: 'percentage_ada',
    display: true,
  },
]

const TOTALS_IN_SMART_CONTRACTS = [
  {
    key: 'value_jpg_listings',
    name: 'JPG.store',
    countKey: 'jpg_listings_count',
    countText: 'Listed NFTs',
  },
  {
    key: 'value_nfts_staked',
    name: 'Staked',
    comingSoon: true,
    countKey: 'staked_count',
    countText: 'Staked NFTs',
  },
  {
    key: 'value_pond',
    name: 'Pond',
    countKey: 'pond_count',
    comingSoon: true,
    countText: 'Active Loans',
  },
]

const ASSET_CATEGORIES = [
  {
    key: 'combined',
    name: 'Combined',
  },
  {
    key: 'nfts',
    name: 'NFTs',
  },
  {
    key: 'tokens',
    name: 'Tokens',
  },
]

export const Portfolio: React.FC = () => {
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false)
  const [portfolio, setPortfolio] = useState({} as PortfolioEntity)
  const [isLoading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([] as Collection[])
  const [tableDataSorted, setTableDataSorted] = useState([] as Collection[])
  const [lastSort, setLastSort] = useState({ value: 'normal' } as SortEntity)
  const [selectedInterval, setSelectedInterval] = useState('24h')
  const [historyLoading, setHistoryLoading] = useState(false)
  const [selectedSeries, setSelectedSeries] = useState(TOTALS_IN_WALLET[0])
  const [valueFromChart, setValueFromChart] = useState(null as ValueFromChart)
  const [dateFromChart, setDateFromChart] = useState(null as DateFromChart)
  const [selectedCategory, setSelectedCategory] = useState(
    ASSET_CATEGORIES[0].key,
  )
  const [showValueTooltip, setShowValueTooltip] = useState(false)
  const [_user, setUser] = useState({} as User)

  const { getPortfolio, getUser } = useApi()
  const { user } = useUser()
  const navigate = useNavigate()

  const fetchPortfolio = async (interval: string, category: string) => {
    if (_user.id && _user.token) {
      try {
        const { portfolio: portfolioFromResponse } = await getPortfolio(
          interval,
          _user.token,
        )

        if (portfolioFromResponse) {
          setPortfolio(portfolioFromResponse)
          if (category === 'nfts') {
            setTableData(portfolioFromResponse.collections)
          } else if (category === 'tokens') {
            setTableData(portfolioFromResponse.tokens)
          } else if (category === 'combined') {
            setTableData(
              portfolioFromResponse.tokens.concat(
                portfolioFromResponse.collections,
              ),
            )
          }
        } else {
          console.log('An unknown error occured')
        }
      } catch (error) {
        console.log('An unknown error occured')
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      if (user.id && user.token) {
        try {
          const { user: userFromResponse } = await getUser(user.id, user.token)

          if (userFromResponse) {
            setUser(userFromResponse)
          } else {
            console.log('An unknown error occured')
          }
        } catch (error) {
          console.log('An unknown error occured')
        }
      }
    }

    fetchUser()
  }, [])

  useEffect(() => {
    if (tableData.length > 0) {
      sortTableData(Object.keys(lastSort)[0], true)
    }
  }, [tableData])

  useEffect(() => {
    const timer = setInterval(() => {
      fetchPortfolio(selectedInterval, selectedCategory)
    }, 60000)

    return () => clearInterval(timer)
  }, [_user, selectedInterval, selectedCategory])

  useEffect(() => {
    fetchPortfolio(selectedInterval, selectedCategory)
  }, [_user])

  const onClickCollection = (collection: Collection) => {
    if (collection.type === 'nft') {
      navigate(`/collections/${collection.id}`)
    } else if (collection.type === 'token') {
      navigate(`/tokens/${collection.id}`)
    }
  }

  const onClickAssetCategory = (key: string) => {
    if (key === 'nfts') {
      setTableData(portfolio.collections)
    } else if (key === 'tokens') {
      setTableData(portfolio.tokens)
    } else if (key === 'combined') {
      setTableData(portfolio.tokens.concat(portfolio.collections))
    }

    setSelectedCategory(key)
  }

  const onCloseUpgradeModal = () => setShowUpgradeMessage(false)

  const sortTableData = (key: string, keep = false) => {
    let result = []
    let direction = ''
    const tableDataCopy = [...tableData]

    if (keep) {
      if (key === 'name') {
        if (lastSort[key] === 'inverted') {
          result = tableDataCopy.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,
          )
        } else {
          result = tableDataCopy.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1,
          )
        }
      } else {
        if (lastSort[key] === 'inverted') {
          result = tableDataCopy.sort((a, b) => {
            const valueA = a[key] as number
            const valueB = b[key] as number
            return valueA - valueB
          })
        } else {
          result = tableDataCopy.sort((a, b) => {
            const valueB = b[key] as number
            const valueA = a[key] as number
            return valueB - valueA
          })
        }
      }
    } else {
      if (key === 'name') {
        if (lastSort[key] === 'normal') {
          result = tableDataCopy.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,
          )
          direction = 'inverted'
        } else {
          result = tableDataCopy.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1,
          )
          direction = 'normal'
        }
      } else {
        if (lastSort[key] === 'normal') {
          result = tableDataCopy.sort((a, b) => {
            const valueA = a[key] as number
            const valueB = b[key] as number
            return valueA - valueB
          })
          direction = 'inverted'
        } else {
          result = tableDataCopy.sort((a, b) => {
            const valueB = b[key] as number
            const valueA = a[key] as number
            return valueB - valueA
          })
          direction = 'normal'
        }
      }
    }

    if (direction) {
      const newLastSort = {} as SortEntity
      newLastSort[key] = direction
      setLastSort(newLastSort)
    }

    setTableDataSorted(result)
  }

  const renderTableHeader = (displayName: string, key: string) => {
    return (
      <div className="flex flex-row gap-1">
        <p className="self-center">{displayName}</p>
        {lastSort[key] === 'inverted' && (
          <IoCaretUp className="self-center text-white" />
        )}

        {lastSort[key] === 'normal' && (
          <IoCaretDown className="self-center text-white" />
        )}
      </div>
    )
  }

  const onIntervalClicked = async (interval: string) => {
    if (historyLoading) {
      return
    }

    setSelectedInterval(interval)
    setHistoryLoading(true)
    await fetchPortfolio(interval, selectedCategory)
    setHistoryLoading(false)
  }

  const yScaleMinMax = () => {
    const history = historyData(selectedSeries.key)
    const minObject = history.reduce(function (prev, current) {
      return prev.y < current.y ? prev : current
    })
    const maxObject = history.reduce(function (prev, current) {
      return prev.y > current.y ? prev : current
    })

    const minValue = minObject.y
    const maxValue = maxObject.y

    return { min: minValue, max: maxValue }
  }

  const historyData = (key: string) => {
    if (_user.pro) {
      if (portfolio.history) {
        return portfolio.history.map((element) => {
          return {
            x: element.key,
            y: element[key] as number,
          }
        })
      } else {
        return []
      }
    } else {
      return FREE_USER_HISTORY.map((element) => {
        return {
          x: element.key,
          y: element[key] as number,
        }
      })
    }
  }

  const onMouseLeaveLineChart = () => {
    setValueFromChart(null)
    setDateFromChart(null)
  }

  const onMouseMoveLineChart = (point: { x: string; y: number }) => {
    setValueFromChart(point.y)
    setDateFromChart(point.x)
  }

  const onClickTotalInWallet = (totalData: {
    key: string
    name: string
    keyChange: string
    keyChangePercentage: string
  }) => {
    if (totalData.key === selectedSeries.key) {
      setSelectedSeries(TOTALS_IN_WALLET[0])
    } else {
      setSelectedSeries(totalData)
    }
  }

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">Portfolio</p>

          <div className="flex flex-row gap-2 mt-12 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        {showUpgradeMessage && <UpgradeModal onClose={onCloseUpgradeModal} />}

        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">Portfolio</p>

          <div className="flex flex-col w-full gap-10 mt-12 lg:flex-row">
            <div className="relative flex flex-col p-6 border rounded-lg lg:w-1/2 2xl:w-1/2 border-neutral-800 bg-stone-900">
              {_user.pro === false && (
                <div className="absolute top-0 left-0 z-10 w-full h-full bg-black rounded-lg bg-opacity-80 hover:bg-opacity-0">
                  <div className="flex flex-col justify-center w-full h-full gap-2">
                    <div className="flex flex-row self-center gap-2">
                      <p className="self-center font-semibold text-neutral-200">
                        Portfolio History requires Poki
                      </p>
                      <p className="self-center px-2 py-1 text-xs font-bold border rounded-lg border-neutral-800 text-neutral-200">
                        PRO
                      </p>
                    </div>
                    <button
                      onClick={() => navigate(RoutePaths.LifetimePass)}
                      className="self-center px-10 py-2 mt-2 text-sm font-bold text-center text-white bg-blue-600 rounded-lg hover:bg-blue-500">
                      Unlock Poki Pro
                    </button>
                  </div>
                </div>
              )}

              <div className="flex flex-row justify-between">
                <div className="relative flex flex-col">
                  <div className="flex flex-row gap-1">
                    <div className="flex flex-row gap-1">
                      {selectedSeries.key !== 'value_total' && (
                        <div className="flex flex-row gap-1">
                          <p
                            className="self-center font-bold cursor-pointer text-neutral-400"
                            onClick={() =>
                              setSelectedSeries(TOTALS_IN_WALLET[0])
                            }>
                            Total
                          </p>
                          <p className="self-center font-bold text-neutral-400">
                            /
                          </p>
                        </div>
                      )}
                      <p
                        className={`self-center font-bold
                          ${
                            selectedSeries.key === 'value_total'
                              ? 'text-neutral-400'
                              : 'text-neutral-200'
                          }`}>
                        {selectedSeries.name}
                      </p>
                    </div>
                    {selectedSeries.key === 'value_total' && (
                      <div className="relative self-center">
                        <IoInformationCircleOutline
                          size={19}
                          color="#a3a3a3"
                          className="self-center cursor-pointer"
                          onMouseEnter={() => setShowValueTooltip(true)}
                          onMouseLeave={() => setShowValueTooltip(false)}
                        />
                        {showValueTooltip && (
                          <div className="absolute p-3 bg-gray-300 rounded-lg w-52 bottom-1 left-5">
                            <p className="text-xs font-semibold">
                              This value includes your Smart Contract (SC)
                              Holdings. However, those SC holdings are not
                              included in the history graphs below.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <p className="text-3xl font-bold text-neutral-200">
                    {valueFromChart !== null
                      ? intToString(valueFromChart as number, true)
                      : intToString(
                          portfolio[selectedSeries.key] as number,
                          true,
                        )}{' '}
                    {ADA_SYMBOL}
                  </p>
                  <p className="text-sm font-semibold text-neutral-400">
                    {dateFromChart !== null
                      ? formatDate(dateFromChart, selectedInterval)
                      : formatDate(new Date().toString(), selectedInterval)}
                  </p>
                  {valueFromChart !== null ? (
                    <div
                      className={`font-bold text-xs ${numberColor(
                        percentageDiff(
                          historyData(selectedSeries.key)[0].y as number,
                          valueFromChart as number,
                        ),
                      )}`}>
                      {`${
                        isPositiveDiff(
                          percentageDiff(
                            historyData(selectedSeries.key)[0].y as number,
                            valueFromChart as number,
                          ),
                        )
                          ? '+'
                          : ''
                      }${formatNumber(
                        ((valueFromChart as number) -
                          historyData(selectedSeries.key)[0].y) as number,
                      )}`}{' '}
                      {ADA_SYMBOL} (
                      {numberWithSign(
                        percentageDiff(
                          historyData(selectedSeries.key)[0].y as number,
                          valueFromChart as number,
                        ),
                      )}
                      %)
                    </div>
                  ) : (
                    <div
                      className={`font-bold text-xs ${numberColor(
                        portfolio?.change[selectedSeries.keyChange],
                      )}`}>
                      {`${
                        isPositiveDiff(
                          portfolio?.change[selectedSeries.keyChange],
                        )
                          ? '+'
                          : ''
                      }${formatNumber(
                        portfolio?.change[selectedSeries.keyChange],
                      )}`}{' '}
                      {ADA_SYMBOL} (
                      {numberWithSign(
                        portfolio?.change[selectedSeries.keyChangePercentage],
                      )}
                      %)
                    </div>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-row gap-2">
                    {INTERVALS.map((intervalData, index) => {
                      return (
                        <p
                          key={`total-interval-${index}`}
                          className={`px-3 py-1 text-xs font-semibold
                              bg-gray-700 rounded-lg cursor-pointer
                              hover:bg-gray-500 text-neutral-200
                              ${
                                intervalData.key === selectedInterval
                                  ? 'bg-gray-500'
                                  : ''
                              }`}
                          onClick={() => onIntervalClicked(intervalData.key)}>
                          {intervalData.display}
                        </p>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className="h-20 mt-12 lg:h-36">
                {historyLoading ? (
                  <div className="flex flex-row justify-center gap-2">
                    <BounceLoader
                      className="self-center"
                      color={'#ffffff'}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />

                    <p className="self-center text-lg font-bold text-neutral-400">
                      Loading
                    </p>
                  </div>
                ) : (
                  <LineChart
                    data={historyData(selectedSeries.key)}
                    yScaleMinMax={yScaleMinMax()}
                    onMouseLeave={onMouseLeaveLineChart}
                    onMouseMove={onMouseMoveLineChart}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col justify-between w-full gap-4 lg:w-1/2">
              <div className="flex flex-col">
                <p className="text-lg font-bold tracking-wide text-neutral-200">
                  Wallet Holdings
                </p>
                <div className="grid w-full grid-cols-3 gap-8 mt-4">
                  {TOTALS_IN_WALLET.filter(
                    (total) => total.display === true,
                  ).map((totalData, index) => {
                    return (
                      <div
                        className={`relative flex flex-col p-4 border rounded-lg bg-stone-900
                          cursor-pointer border-neutral-800 hover:border-neutral-600
                          ${
                            selectedSeries.key === totalData.key
                              ? 'border-neutral-600'
                              : ''
                          }
                          `}
                        key={`totals-${index}`}
                        onClick={() => onClickTotalInWallet(totalData)}>
                        <p className="text-sm font-bold text-neutral-400">
                          {totalData.name}
                        </p>
                        {_user.pro === false &&
                          totalData.proRequired === true && (
                            <p className="absolute px-2 py-1 text-xs font-bold text-white border rounded-lg right-2 top-2 border-neutral-800">
                              PRO
                            </p>
                          )}
                        <p className="text-lg font-bold text-neutral-200">
                          {intToString(
                            portfolio[totalData.key] as number,
                            true,
                          )}{' '}
                          {ADA_SYMBOL}
                        </p>
                        {_user.pro === false &&
                        totalData.proRequired === true ? (
                          <p className="text-xs font-bold text-neutral-200">
                            Pro required
                          </p>
                        ) : (
                          <div
                            className={`font-bold text-xs ${numberColor(
                              portfolio?.change[totalData.keyChange],
                            )}`}>
                            {`${
                              isPositiveDiff(
                                portfolio?.change[totalData.keyChange],
                              )
                                ? '+'
                                : ''
                            }${formatNumber(
                              portfolio?.change[totalData.keyChange],
                            )}`}{' '}
                            {ADA_SYMBOL} (
                            {numberWithSign(
                              portfolio?.change[totalData.keyChangePercentage],
                            )}
                            %)
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-lg font-bold tracking-wide text-neutral-200">
                  Smart Contract Holdings
                </p>
                <div className="grid w-full grid-cols-3 gap-8 mt-4">
                  {TOTALS_IN_SMART_CONTRACTS.map((totalData, index) => {
                    return (
                      <div
                        className={`relative flex flex-col p-4 border rounded-lg border-neutral-800 bg-stone-900 ${
                          totalData.comingSoon ? 'opacity-50' : 'opacity-100'
                        }`}
                        key={`totals-${index}`}>
                        <p className="text-sm font-bold text-neutral-400">
                          {totalData.name}
                        </p>
                        {_user.pro === false && (
                          <p className="absolute px-2 py-1 text-xs font-bold text-white border rounded-lg right-2 top-2 border-neutral-800">
                            PRO
                          </p>
                        )}
                        {totalData.comingSoon ? (
                          <div className="flex flex-col">
                            <p className="text-lg font-bold text-neutral-200">
                              0 {ADA_SYMBOL}
                            </p>
                            <p className="text-xs font-bold text-neutral-200">
                              Coming Soon
                            </p>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <p className="text-lg font-bold text-neutral-200">
                              {intToString(
                                portfolio[totalData.key] as number,
                                true,
                              )}{' '}
                              {ADA_SYMBOL}
                            </p>
                            {_user.pro === false ? (
                              <p className="text-xs font-bold text-neutral-200">
                                Pro required
                              </p>
                            ) : (
                              <p className="text-xs font-bold text-neutral-200">
                                {portfolio[totalData.countKey] as number}{' '}
                                {totalData.countText}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <p className="mt-12 text-2xl font-bold tracking-wide text-white">
            Your Assets
          </p>

          <div className="flex flex-row gap-4">
            {ASSET_CATEGORIES.map((categoryData, index) => {
              return (
                <div
                  className="flex flex-row gap-4"
                  key={`asset-category-${index}`}>
                  <p
                    className={`self-center mt-4 font-bold cursor-pointer
                   ${
                     categoryData.key === selectedCategory
                       ? 'text-neutral-200'
                       : 'text-neutral-400'
                   }`}
                    onClick={() => onClickAssetCategory(categoryData.key)}>
                    {categoryData.name}
                  </p>
                  {index < ASSET_CATEGORIES.length - 1 && (
                    <p className="self-center mt-4 font-bold text-neutral-200">
                      |
                    </p>
                  )}
                </div>
              )
            })}
          </div>

          <div className="flex self-center justify-center w-full mt-8 overflow-auto">
            <table className="w-full overflow-auto text-xs font-bold text-left table-auto">
              <thead className="text-neutral-200 bg-neutral-800">
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('name')}>
                  {renderTableHeader('Name', 'name')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('price')}>
                  {renderTableHeader(`Price(${ADA_SYMBOL})`, 'price')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('change_percentage')}>
                  {renderTableHeader(
                    `${selectedInterval} %`,
                    'change_percentage',
                  )}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('count')}>
                  {renderTableHeader('Asset Count', 'count')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('value')}>
                  {renderTableHeader(`Value(${ADA_SYMBOL})`, 'value')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('share')}>
                  {renderTableHeader('Portfolio Share', 'share')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('share_category')}>
                  {renderTableHeader('Category Share', 'share_category')}
                </th>
              </thead>
              <tbody>
                {tableDataSorted.map((collection, index) => {
                  return (
                    <tr
                      className="text-xs border-b text-neutral-200 border-neutral-800"
                      key={`collection-${index}`}>
                      <td
                        className="flex flex-row gap-2 px-6 py-3 cursor-pointer"
                        onClick={() => onClickCollection(collection)}>
                        {collection.image ? (
                          <img
                            src={collection.image}
                            className="self-center w-10"
                          />
                        ) : (
                          <div className="flex self-center justify-center w-10 h-10 rounded-full bg-neutral-800">
                            <p className="self-center text-xl font-normal">?</p>
                          </div>
                        )}
                        <p className="self-center font-bold">
                          {collection.name}
                        </p>
                      </td>
                      <td className="px-6 py-3">
                        {intToString(collection.price)}
                      </td>
                      <td
                        className={`${numberColor(
                          collection.change_percentage,
                        )} px-6 py-3`}>
                        {numberWithSign(collection.change_percentage)}%
                      </td>
                      <td className="px-6 py-3">
                        {intToString(collection.count)}
                      </td>
                      <td className="px-6 py-3">
                        {intToString(collection.value)}
                      </td>
                      <td className="px-6 py-3">{collection.share}%</td>
                      <td className="px-6 py-3">
                        {collection.share_category}%
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
