import React from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import UserContext from './context/UserContext'
import useLocalStorageState from './hooks/useLocalStorage'
import { RoutePaths } from './route-paths'
import { Root } from './pages/Root'
import { ConnectWallet } from './pages/ConnectWallet'
import { Collection } from './pages/Collection'
import { AccountOverview } from './pages/AccountOverview'
import { Portfolio } from './pages/Portfolio'
import { Header } from './components/Header'
import { User } from './hooks/useApi'
import { LifetimePass } from './pages/LifetimePass'
import { Privacy } from './pages/Privacy'
import { LiveMarket } from './pages/LiveMarket'
import { Roadmap } from './pages/Roadmap'
import { Contact } from './pages/Contact'
import { MagicLogin } from './pages/MagicLogin'
import { DiscordOauth } from './pages/DiscordOauth'
import { Analytics } from './pages/Analytics'
import { Token } from './pages/Token'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

const LOCAL_STORAGE_USER_KEY = 'poki.user'

function App() {
  const userState = useLocalStorageState(LOCAL_STORAGE_USER_KEY, '{}')
  const [user, setUser] = userState

  TimeAgo.setDefaultLocale(en.locale)
  TimeAgo.addLocale(en)

  const ProtectedRoute = ({
    user,
    redirectPath = RoutePaths.ConnectWallet,
  }: {
    user: User
    redirectPath?: string
  }) => {
    if (!user?.token) {
      return <Navigate to={redirectPath} />
    }

    return <Outlet />
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Header />
      <Routes>
        <Route path={RoutePaths.Root} element={<Root />} />
        <Route path={RoutePaths.ConnectWallet} element={<ConnectWallet />} />
        <Route path={RoutePaths.LifetimePass} element={<LifetimePass />} />
        <Route path={RoutePaths.LiveMarket} element={<LiveMarket />} />
        <Route path={RoutePaths.Privacy} element={<Privacy />} />
        <Route path={RoutePaths.MagicLogin} element={<MagicLogin />} />
        <Route path={RoutePaths.Roadmap} element={<Roadmap />} />
        <Route path={RoutePaths.Contact} element={<Contact />} />
        <Route path={RoutePaths.Collection} element={<Collection />} />
        <Route path={RoutePaths.Token} element={<Token />} />

        <Route element={<ProtectedRoute user={user} />}>
          <Route
            path={RoutePaths.AccountOverview}
            element={<AccountOverview />}
          />
          <Route path={RoutePaths.Portfolio} element={<Portfolio />} />
          <Route path={RoutePaths.Analytics} element={<Analytics />} />
          <Route path={RoutePaths.DiscordOauth} element={<DiscordOauth />} />
        </Route>

        <Route path="*" element={<Navigate to={RoutePaths.Root} />} />
      </Routes>
    </UserContext.Provider>
  )
}

export default App
