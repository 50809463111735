import { HistoryEntity } from '../hooks/useApi'

interface FloorThicknessColor {
  [key: number]: string
}

interface FloorThickness {
  [key: number]: string
}

interface IntervalMapping {
  [key: string]: string
}

interface ReasonColors {
  [key: string]: string
}

export const isPositiveDiff = (value = 0.0) => value >= 0.0
export const numberColor = (number = 0.0) =>
  isPositiveDiff(number) ? 'text-green-500' : 'text-red-500'
export const formatNumber = (number = 0.0, digits = 0) =>
  number.toLocaleString(undefined, { maximumFractionDigits: digits })
export const numberWithSign = (number = 0.0, digits = 0) =>
  `${isPositiveDiff(number) ? '+' : ''}${formatNumber(number, digits)}`
export const intToString = (num = 0.0, format = false): string => {
  if (num < 1000) {
    if (format) {
      return formatNumber(num, 2)
    } else {
      return num.toString()
    }
  }
  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ]
  let index
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break
    }
  }
  return (
    (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
    si[index].s
  )
}

export const formatXAxis = (value: string, interval: string) => {
  const date = new Date(value)
  if (interval === '24h') {
    return `${date.getHours()}:00`
  } else {
    return `${date.getMonth() + 1}/${date.getDate()}`
  }
}

export const formatDate = (value: string, selectedInterval: string) => {
  const date = new Date(value)
  let options = {}
  if (selectedInterval == '24h') {
    options = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
  } else {
    options = {
      month: 'short',
      day: 'numeric',
    }
  }

  return date.toLocaleString('default', options)
}

export const percentageDiff = (base: number, current: number) => {
  return ((current - base) / base) * 100
}

export const ADA_SYMBOL = '₳'

export const FLOOR_THICKNESS_COLOR_MAPPING: FloorThicknessColor = [
  'bg-green-600',
  'bg-blue-600',
  'bg-orange-600',
  'bg-red-600',
]

export const FLOOR_THICKNESS_MAPPING: FloorThickness = [
  'Low',
  'Medium',
  'High',
  'Very High',
]

export const INTERVALS = [
  {
    display: '24H',
    key: '24h',
  },
  {
    display: '1W',
    key: '7d',
  },
  {
    display: '1M',
    key: '30d',
  },
  {
    display: '3M',
    key: '90d',
  },
  {
    display: '6M',
    key: '180d',
  },
]

export const REASON_COLORS: ReasonColors = {
  'floor change': 'text-blue-400',
  'sales volume': 'text-yellow-400',
  'high sale': 'text-green-400',
  'thin floor': 'text-purple-400',
}

export const INTERVAL_MAPPING: IntervalMapping = {
  '24h': '24 Hours',
  '7d': '1 Week',
  '30d': '1 Month',
  '90d': '3 Months',
  '180d': '6 Months',
}

export const FREE_USER_HISTORY: HistoryEntity[] = [
  {
    key: '2022-11-10T23:59:59Z',
    value_total: 120796.7862540949,
    value_ada: 5729.192586000013,
    value_nfts: 103292,
    value_coins: 11775.593668094883,
  },
  {
    key: '2022-11-11T23:59:59Z',
    value_total: 119507.3984055361,
    value_ada: 5360.150302000013,
    value_nfts: 102742,
    value_coins: 11405.248103536092,
  },
  {
    key: '2022-11-12T23:59:59Z',
    value_total: 119789.6669180615,
    value_ada: 4741.523396000012,
    value_nfts: 102401,
    value_coins: 12647.143522061473,
  },
  {
    key: '2022-11-13T23:59:59Z',
    value_total: 118373.19290564943,
    value_ada: 5214.023396000012,
    value_nfts: 101076,
    value_coins: 12083.169509649408,
  },
  {
    key: '2022-11-14T23:59:59Z',
    value_total: 118774.42165497996,
    value_ada: 5203.875720000013,
    value_nfts: 100819,
    value_coins: 12751.54593497995,
  },
  {
    key: '2022-11-15T23:59:59Z',
    value_total: 115102.30436629718,
    value_ada: 2273.7251320000128,
    value_nfts: 100030,
    value_coins: 12798.579234297169,
  },
  {
    key: '2022-11-16T23:59:59Z',
    value_total: 114500.20370989827,
    value_ada: 2275.719132000013,
    value_nfts: 99820,
    value_coins: 12404.484577898253,
  },
  {
    key: '2022-11-17T23:59:59Z',
    value_total: 110602.36393489753,
    value_ada: 1038.2051380000128,
    value_nfts: 98359,
    value_coins: 11205.158796897513,
  },
  {
    key: '2022-11-18T23:59:59Z',
    value_total: 110477.91759635469,
    value_ada: 1038.2051380000128,
    value_nfts: 99069,
    value_coins: 10370.71245835467,
  },
  {
    key: '2022-11-19T23:59:59Z',
    value_total: 110211.66774231628,
    value_ada: 431.1365920000127,
    value_nfts: 99408,
    value_coins: 10372.531150316267,
  },
  {
    key: '2022-11-20T23:59:59Z',
    value_total: 112502.19732860866,
    value_ada: 428.8378010000127,
    value_nfts: 101009,
    value_coins: 11064.35952760864,
  },
  {
    key: '2022-11-21T23:59:59Z',
    value_total: 114371.92652272468,
    value_ada: 428.8378010000127,
    value_nfts: 102571,
    value_coins: 11372.088721724667,
  },
  {
    key: '2022-11-22T23:59:59Z',
    value_total: 113820.18858737845,
    value_ada: 1190.0878010000126,
    value_nfts: 100750,
    value_coins: 11880.100786378433,
  },
  {
    key: '2022-11-23T23:59:59Z',
    value_total: 112753.53338358925,
    value_ada: 1124.2281030000122,
    value_nfts: 99725,
    value_coins: 11904.305280589237,
  },
  {
    key: '2022-11-24T23:59:59Z',
    value_total: 111900.61765486906,
    value_ada: 668.7742700000123,
    value_nfts: 99288,
    value_coins: 11943.843384869046,
  },
  {
    key: '2022-11-25T23:59:59Z',
    value_total: 113637.78052031345,
    value_ada: 3251.345760000013,
    value_nfts: 98810,
    value_coins: 11576.434760313441,
  },
  {
    key: '2022-11-26T23:59:59Z',
    value_total: 110479.80942352144,
    value_ada: 340.29620900001237,
    value_nfts: 98539,
    value_coins: 11600.513214521427,
  },
  {
    key: '2022-11-27T23:59:59Z',
    value_total: 115384.75503201058,
    value_ada: 6245.454986000013,
    value_nfts: 97376,
    value_coins: 11763.300046010569,
  },
  {
    key: '2022-11-28T23:59:59Z',
    value_total: 116569.71068823626,
    value_ada: 7282.9725000000135,
    value_nfts: 97455,
    value_coins: 11831.73818823624,
  },
  {
    key: '2022-11-29T23:59:59Z',
    value_total: 117998.3632004284,
    value_ada: 7814.834432000014,
    value_nfts: 98409,
    value_coins: 11774.528768428387,
  },
  {
    key: '2022-11-30T23:59:59Z',
    value_total: 121242.97993812663,
    value_ada: 9562.338652000013,
    value_nfts: 99906,
    value_coins: 11774.64128612663,
  },
  {
    key: '2022-12-01T23:59:59Z',
    value_total: 121738.12163734529,
    value_ada: 10351.410318000013,
    value_nfts: 100013,
    value_coins: 11373.711319345277,
  },
  {
    key: '2022-12-02T23:59:59Z',
    value_total: 120341.83873426629,
    value_ada: 7200.6584690000145,
    value_nfts: 100520,
    value_coins: 12621.180265266266,
  },
  {
    key: '2022-12-03T23:59:59Z',
    value_total: 119792.16841124992,
    value_ada: 7200.6584690000145,
    value_nfts: 99675,
    value_coins: 12916.50994224991,
  },
  {
    key: '2022-12-04T23:59:59Z',
    value_total: 128103.54205157081,
    value_ada: 16700.476512000016,
    value_nfts: 98778,
    value_coins: 12625.065539570793,
  },
  {
    key: '2022-12-05T23:59:59Z',
    value_total: 132996.43491689255,
    value_ada: 19350.476512000016,
    value_nfts: 101728,
    value_coins: 11917.958404892532,
  },
  {
    key: '2022-12-06T23:59:59Z',
    value_total: 188444.83672267973,
    value_ada: 72131.86035200002,
    value_nfts: 103058,
    value_coins: 13254.976370679715,
  },
  {
    key: '2022-12-07T23:59:59Z',
    value_total: 174320.59179042347,
    value_ada: 56374.55836600002,
    value_nfts: 104598,
    value_coins: 13348.033424423447,
  },
  {
    key: '2022-12-08T23:59:59Z',
    value_total: 175594.11861529743,
    value_ada: 56374.55836600002,
    value_nfts: 106165,
    value_coins: 13054.560249297429,
  },
  {
    key: '2022-12-09T23:59:59Z',
    value_total: 178379.59162874566,
    value_ada: 56972.80327000002,
    value_nfts: 108070,
    value_coins: 13336.788358745629,
  },
  {
    key: '2022-12-10T23:59:59Z',
    value_total: 176175.68178513134,
    value_ada: 54345.69161500002,
    value_nfts: 109542,
    value_coins: 12287.99017013131,
  },
  {
    key: '2022-12-11T23:59:59Z',
    value_total: 177869.36276322274,
    value_ada: 56404.76363600002,
    value_nfts: 109018,
    value_coins: 12446.599127222724,
  },
  {
    key: '2022-12-12T23:59:59Z',
    value_total: 176121.95437881557,
    value_ada: 56404.76363600002,
    value_nfts: 106533,
    value_coins: 13184.190742815541,
  },
  {
    key: '2022-12-13T23:59:59Z',
    value_total: 176010.81556747173,
    value_ada: 56591.08512600002,
    value_nfts: 106247,
    value_coins: 13172.730441471698,
  },
  {
    key: '2022-12-14T23:59:59Z',
    value_total: 144903.8196218689,
    value_ada: 23004.513241000022,
    value_nfts: 108216,
    value_coins: 13683.30638086889,
  },
  {
    key: '2022-12-15T23:59:59Z',
    value_total: 142982.14574808071,
    value_ada: 22441.499518000022,
    value_nfts: 106874,
    value_coins: 13666.646230080687,
  },
  {
    key: '2022-12-16T23:59:59Z',
    value_total: 140472.07724178402,
    value_ada: 19450.514949000026,
    value_nfts: 106822,
    value_coins: 14199.562292783998,
  },
  {
    key: '2022-12-17T23:59:59Z',
    value_total: 144769.22942065282,
    value_ada: 21967.876542000024,
    value_nfts: 107703,
    value_coins: 15098.352878652799,
  },
  {
    key: '2022-12-18T23:59:59Z',
    value_total: 142617.16042238168,
    value_ada: 21683.949352000025,
    value_nfts: 107297,
    value_coins: 13636.211070381663,
  },
  {
    key: '2022-12-19T23:59:59Z',
    value_total: 139794.3005554595,
    value_ada: 13020.209280000023,
    value_nfts: 108180,
    value_coins: 18594.091275459472,
  },
  {
    key: '2022-12-20T23:59:59Z',
    value_total: 137140.7369322724,
    value_ada: 8024.740400000022,
    value_nfts: 111546,
    value_coins: 17569.996532272387,
  },
  {
    key: '2022-12-21T23:59:59Z',
    value_total: 143698.07703692684,
    value_ada: 9691.921212000023,
    value_nfts: 116430,
    value_coins: 17576.155824926824,
  },
  {
    key: '2022-12-22T23:59:59Z',
    value_total: 144374.20949905255,
    value_ada: 9734.791113000021,
    value_nfts: 117108,
    value_coins: 17531.418386052548,
  },
  {
    key: '2022-12-23T23:59:59Z',
    value_total: 152618.33192914532,
    value_ada: 9734.791113000021,
    value_nfts: 125332,
    value_coins: 17551.540816145316,
  },
  {
    key: '2022-12-24T23:59:59Z',
    value_total: 159256.23143447758,
    value_ada: 9734.791113000021,
    value_nfts: 130290,
    value_coins: 19231.44032147756,
  },
  {
    key: '2022-12-25T23:59:59Z',
    value_total: 157100.8247457546,
    value_ada: 8168.361611000021,
    value_nfts: 129698,
    value_coins: 19234.46313475456,
  },
  {
    key: '2022-12-26T23:59:59Z',
    value_total: 155596.99077979007,
    value_ada: 7046.173898000021,
    value_nfts: 129332,
    value_coins: 19218.816881790066,
  },
  {
    key: '2022-12-27T23:59:59Z',
    value_total: 159527.61970412146,
    value_ada: 8382.21738100002,
    value_nfts: 131932,
    value_coins: 19213.40232312144,
  },
  {
    key: '2022-12-28T23:59:59Z',
    value_total: 167859.147278824,
    value_ada: 9811.011784000024,
    value_nfts: 139873,
    value_coins: 18175.135494823986,
  },
  {
    key: '2022-12-29T23:59:59Z',
    value_total: 180457.2739818289,
    value_ada: 4100.002197000025,
    value_nfts: 154841,
    value_coins: 21516.271784828896,
  },
  {
    key: '2022-12-30T23:59:59Z',
    value_total: 172596.6512751101,
    value_ada: 9015.196937000026,
    value_nfts: 145463,
    value_coins: 18118.454338110085,
  },
  {
    key: '2022-12-31T23:59:59Z',
    value_total: 174066.03937815782,
    value_ada: 10224.270187000027,
    value_nfts: 145645,
    value_coins: 18196.769191157786,
  },
  {
    key: '2023-01-01T23:59:59Z',
    value_total: 177100.69096491628,
    value_ada: 10224.270187000027,
    value_nfts: 151162,
    value_coins: 15714.420777916277,
  },
  {
    key: '2023-01-02T23:59:59Z',
    value_total: 214968.1901947763,
    value_ada: 14449.215835000028,
    value_nfts: 184425,
    value_coins: 16093.974359776274,
  },
  {
    key: '2023-01-03T23:59:59Z',
    value_total: 215655.05572313754,
    value_ada: 18544.55846200003,
    value_nfts: 178741,
    value_coins: 18369.497261137487,
  },
  {
    key: '2023-01-04T23:59:59Z',
    value_total: 213945.85066632446,
    value_ada: 18544.35494500003,
    value_nfts: 176367,
    value_coins: 19034.49572132443,
  },
  {
    key: '2023-01-05T23:59:59Z',
    value_total: 207574.07034322934,
    value_ada: 16068.227705000027,
    value_nfts: 173218,
    value_coins: 18287.842638229326,
  },
  {
    key: '2023-01-06T23:59:59Z',
    value_total: 215169.14416031225,
    value_ada: 16925.190067000025,
    value_nfts: 179950,
    value_coins: 18293.95409331222,
  },
  {
    key: '2023-01-07T23:59:59Z',
    value_total: 243163.13641387224,
    value_ada: 40095.48976400003,
    value_nfts: 184205,
    value_coins: 18862.646649872207,
  },
  {
    key: '2023-01-08T23:59:59Z',
    value_total: 233339.75755945742,
    value_ada: 32049.245630000027,
    value_nfts: 182455,
    value_coins: 18835.5119294574,
  },
  {
    key: '2023-01-09T23:59:59Z',
    value_total: 197632.04360630002,
    value_ada: 26466.895571000026,
    value_nfts: 152725,
    value_coins: 18440.148035299982,
  },
  {
    key: '2023-01-10T23:59:59Z',
    value_total: 183919.36079601443,
    value_ada: 13324.807047000028,
    value_nfts: 152335,
    value_coins: 18259.553749014376,
  },
  {
    key: '2023-01-11T23:59:59Z',
    value_total: 166418.73833892023,
    value_ada: 3015.74028300003,
    value_nfts: 146644,
    value_coins: 16758.99805592021,
  },
]
