import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { BounceLoader } from 'react-spinners'
import { useUser } from '../../context/UserContext'
import useApi from '../../hooks/useApi'

interface Props {
  onClose: () => void
}

export const AddWalletModal: React.FC<Props> = ({ onClose }) => {
  const [walletAddress, setWalletAddress] = useState('')
  const [walletName, setWalletName] = useState('')
  const [apiError, setApiError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const { addWallet } = useApi()
  const { user, setUser } = useUser()

  const createWallet = async () => {
    if (user.token) {
      try {
        setSubmitting(true)
        const response = await addWallet(walletAddress, walletName, user.token)

        if (response.error === 'stake_addr_null') {
          setApiError(
            'Please provide one the following: One of your used addresses, your stake address or your $handle.',
          )
        } else if (response.error === 'wallet_exists') {
          setApiError('Wallet exists already.')
        } else if (response.accounts) {
          setUser({ ...user, ...{ accounts: response.accounts } })
          setApiError('')
          onClose()
        } else {
          setApiError('An unknown Error occured.')
        }
      } catch (error) {
        console.log(error)
        setApiError('An unknown Error occured.')
      } finally {
        setSubmitting(false)
      }
    }
  }

  const handleWalletAddressChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setWalletAddress(e.target.value)
  const handleWalletNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setWalletName(e.target.value)

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      createWallet()
    } else if (e.key === 'Escape') {
      onClose()
    }
  }

  return (
    <>
      <div
        className="fixed z-20 w-full h-full transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-95 top-1/2 left-1/2"
        onKeyDown={handleKeypress}>
        <p
          className="absolute text-sm font-bold text-white top-3 hover:cursor-pointer right-4"
          onClick={() => onClose()}>
          <IoClose size={20} />
        </p>
        <div className="flex flex-col justify-center w-full h-full px-20 py-10">
          <div className="self-center w-full lg:w-2/3 3xl:w-1/2">
            <p className="text-6xl font-bold text-neutral-200">Add Wallet</p>

            <p className="mt-10 text-xs text-neutral-200">
              Enter your Cardano Wallet Address or $handle
            </p>

            <input
              type={'text'}
              value={walletAddress}
              onChange={handleWalletAddressChange}
              placeholder={'Wallet Address or $handle'}
              autoFocus
              className="w-full pb-1 mt-2 font-bold text-white bg-transparent border-b border-neutral-700 placeholder:text-neutral-600 placeholder:font-bold focus:ring-0 focus:outline-none"
            />

            <p className="mt-10 text-xs text-neutral-200">
              Enter a name for your Wallet
            </p>

            <input
              type={'text'}
              value={walletName}
              onChange={handleWalletNameChange}
              placeholder={'Wallet Name'}
              className="w-full pb-1 mt-2 font-bold text-white bg-transparent border-b border-neutral-700 placeholder:text-neutral-600 placeholder:font-bold focus:ring-0 focus:outline-none"
            />

            <div className="flex flex-row gap-5">
              <button
                className={`self-center w-3/4 px-12 py-3 mt-12 ${
                  isSubmitting ? 'bg-blue-400' : 'bg-blue-500'
                } rounded-lg hover:bg-blue-400 lg:w-1/3 3xl:w-1/4`}
                onClick={createWallet}
                disabled={isSubmitting}>
                <div className="flex flex-row justify-center gap-2">
                  {isSubmitting && (
                    <BounceLoader
                      color={'#ffffff'}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                  <p className="self-center text-xs font-bold text-white">
                    Submit
                  </p>
                </div>
              </button>

              <button
                className="self-center w-3/4 px-12 py-3 mt-12 rounded-lg bg-neutral-900 hover:bg-neutral-700 lg:w-1/3 3xl:w-1/4"
                onClick={onClose}>
                <p className="text-xs font-bold text-white">Cancel</p>
              </button>
            </div>

            {apiError && (
              <p className="mt-4 text-xs text-red-400">
                Oops, that didn&apos;t work. {apiError}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
