import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext'
import useApi, { CollectionCombined } from '../../hooks/useApi'
import TimeAgo from 'javascript-time-ago'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import {
  ADA_SYMBOL,
  INTERVALS,
  INTERVAL_MAPPING,
  formatDate,
  intToString,
  numberColor,
  numberWithSign,
  percentageDiff,
  REASON_COLORS,
} from '../../util/format'
import { LineChart } from '../../components/LineChart'

type ValueFromChart = number | null
type DateFromChart = string | null

export const Collection: React.FC = () => {
  const [collection, setCollection] = useState({} as CollectionCombined)
  const [isLoading, setLoading] = useState(true)
  const [selectedInterval, setSelectedInterval] = useState('24h')
  const [historyLoading, setHistoryLoading] = useState(false)
  const [valueFromChart, setValueFromChart] = useState(null as ValueFromChart)
  const [dateFromChart, setDateFromChart] = useState(null as DateFromChart)

  const { id } = useParams()
  const { getCollection } = useApi()

  const timeAgo = new TimeAgo('en-US')

  const fetchCollection = async (interval: string) => {
    if (id) {
      try {
        const result = await getCollection(id, interval)
        if (result.collection) {
          setCollection(result.collection)
          setLoading(false)
        } else {
          console.log('An unknown error occured')
        }
      } catch (error) {
        console.log('An unknown error occured')
      }
    }
  }

  useEffect(() => {
    fetchCollection(selectedInterval)
  }, [])

  const onIntervalClicked = async (interval: string) => {
    if (historyLoading) {
      return
    }

    setSelectedInterval(interval)
    setHistoryLoading(true)
    await fetchCollection(interval)
    setHistoryLoading(false)
  }

  const collectionHistory = () => {
    return collection.stats.history.map((element) => {
      return {
        x: element.key,
        y: element.value,
      }
    })
  }

  const yScaleMinMax = () => {
    const history = collectionHistory()
    const minObject = history.reduce(function (prev, current) {
      return prev.y < current.y ? prev : current
    })
    const maxObject = history.reduce(function (prev, current) {
      return prev.y > current.y ? prev : current
    })

    const minValue = minObject.y
    const maxValue = maxObject.y

    return { min: minValue, max: maxValue }
  }

  const onMouseLeaveLineChart = () => {
    setValueFromChart(null)
    setDateFromChart(null)
  }

  const onMouseMoveLineChart = (point: { x: string; y: number }) => {
    setValueFromChart(point.y)
    setDateFromChart(point.x)
  }

  const intervalFormatted = () => INTERVAL_MAPPING[selectedInterval]

  const getTimeAgo = (createdAt: string) => {
    return timeAgo.format(new Date(createdAt))
  }

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 lg:w-2/3 3xl:w-1/2">
          <div className="flex flex-row gap-2 mt-10 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <div className="flex flex-row gap-10">
            <img
              src={collection.image}
              className="object-cover w-1/3 rounded-xl"
            />
            <div className="flex flex-col w-full">
              <p className="text-6xl font-bold text-neutral-200">
                {collection.name}
              </p>

              <a
                href={`https://jpg.store/collection/${collection.jpg_store_name}`}
                target="_blank"
                rel="noreferrer"
                className="self-start px-3 py-2 mt-4 text-sm font-semibold text-black bg-yellow-400 rounded-lg hover:bg-yellow-300 hover:cursor-pointer">
                View on JPG.store
              </a>

              <div className="flex flex-col p-6 mt-8 border rounded-lg border-neutral-800 bg-stone-900">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col w-full">
                    <p className="font-bold text-neutral-400">Price</p>
                    <p className="text-3xl font-bold text-neutral-200">
                      {valueFromChart !== null
                        ? `${valueFromChart} ${ADA_SYMBOL}`
                        : `${collection.stats.floor} ${ADA_SYMBOL}`}
                    </p>

                    <p className="text-sm font-semibold text-neutral-400">
                      {dateFromChart !== null
                        ? formatDate(dateFromChart, selectedInterval)
                        : formatDate(new Date().toString(), selectedInterval)}
                    </p>

                    {valueFromChart !== null ? (
                      <div
                        className={`font-bold ${numberColor(
                          percentageDiff(
                            collectionHistory()[0].y,
                            valueFromChart,
                          ),
                        )}`}>
                        {numberWithSign(
                          valueFromChart - collectionHistory()[0].y,
                        )}{' '}
                        ₳ (
                        {numberWithSign(
                          percentageDiff(
                            collectionHistory()[0].y,
                            valueFromChart,
                          ),
                        )}
                        %)
                      </div>
                    ) : (
                      <div
                        className={`font-bold ${numberColor(
                          collection.stats.change.percentage,
                        )}`}>
                        {numberWithSign(collection.stats.change.total)} ₳ (
                        {collection.stats.change.percentage}%)
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className="flex flex-row gap-2">
                      {INTERVALS.map((intervalData, index) => {
                        return (
                          <p
                            key={`total-interval-${index}`}
                            className={`px-3 py-1 text-xs font-semibold
                            bg-gray-700 rounded-lg cursor-pointer
                            hover:bg-gray-500 text-neutral-200
                            ${
                              intervalData.key === selectedInterval
                                ? 'bg-gray-500'
                                : ''
                            }`}
                            onClick={() => onIntervalClicked(intervalData.key)}>
                            {intervalData.display}
                          </p>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className="h-20 mt-12 lg:h-36">
                  {historyLoading ? (
                    <div className="flex flex-row justify-center gap-2">
                      <BounceLoader
                        className="self-center"
                        color={'#ffffff'}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />

                      <p className="self-center text-lg font-bold text-neutral-400">
                        Loading
                      </p>
                    </div>
                  ) : (
                    <LineChart
                      data={collectionHistory()}
                      yScaleMinMax={yScaleMinMax()}
                      onMouseLeave={onMouseLeaveLineChart}
                      onMouseMove={onMouseMoveLineChart}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <p className="mt-12 text-2xl font-bold tracking-wide text-white">
            Sales Stats
          </p>

          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="relative flex flex-col p-4 border rounded-lg bg-stone-900 border-stone-800">
              <p className="text-sm font-bold text-neutral-400">
                Sales Count ({intervalFormatted()})
              </p>

              <p className="text-lg font-bold text-neutral-200">
                {intToString(collection.stats.sales_count)}
              </p>
            </div>

            <div className="relative flex flex-col p-4 border rounded-lg bg-stone-900 border-stone-800">
              <p className="text-sm font-bold text-neutral-400">
                Sales Volume ({intervalFormatted()})
              </p>

              <p className="text-lg font-bold text-neutral-200">
                {intToString(collection.stats.sales_volume)} {ADA_SYMBOL}
              </p>
            </div>

            <div className="relative flex flex-col p-4 border rounded-lg bg-stone-900 border-stone-800">
              <p className="text-sm font-bold text-neutral-400">
                Unique Buyers ({intervalFormatted()})
              </p>

              <p className="text-lg font-bold text-neutral-200">
                {intToString(collection.stats.unique_buyers)}
              </p>
            </div>
          </div>

          <p className="mt-12 text-2xl font-bold tracking-wide text-white">
            Alerts
          </p>

          <div className="mt-4">
            {collection.stats.alerts.length === 0 && (
              <div className="relative flex flex-row justify-center gap-3 p-5 text-sm font-semibold border border-stone-800 bg-stone-900 rounded-xl text-neutral-300 md:h-40">
                <p className="self-center text-neutral-400">
                  No alerts in the selected period.
                </p>
              </div>
            )}

            <div className="flex flex-col gap-4">
              {collection.stats.alerts.map((alert, index) => {
                return (
                  <div
                    className="relative flex flex-row gap-3 p-3 text-xs font-semibold border border-stone-800 bg-stone-900 rounded-xl text-neutral-300"
                    key={`alert-${index}`}>
                    <div className="flex flex-col">
                      <p className="text-base font-bold text-neutral-500">
                        <span
                          className={`font-bold capitalize ${
                            REASON_COLORS[alert.alert_type]
                          }`}>
                          {alert.alert_type}
                        </span>{' '}
                      </p>

                      <p className="text-xs text-neutral-500">
                        {getTimeAgo(alert.created_at)} -{' '}
                        {formatDate(alert.created_at, selectedInterval)}
                      </p>

                      <div className="mt-3">
                        {alert.alert_type === 'high sale' && (
                          <p>
                            Sold for: {alert.metadata.price} {ADA_SYMBOL}
                          </p>
                        )}

                        {alert.alert_type === 'floor change' && (
                          <p>
                            Change:{' '}
                            <span
                              className={`${numberColor(
                                alert.metadata.floor_change,
                              )}`}>
                              {numberWithSign(alert.metadata.floor_change)}%
                            </span>
                          </p>
                        )}

                        {alert.alert_type === 'sales volume' && (
                          <p>Sales count: {alert.metadata.sales_count}</p>
                        )}

                        {alert.alert_type === 'thin floor' && (
                          <p>Listings to 2x Floor: {alert.metadata.value}</p>
                        )}
                      </div>

                      <div className="mt-1">
                        <p>
                          Floor during Alert: {alert.current_floor} {ADA_SYMBOL}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
