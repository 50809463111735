import React from 'react'
import { IoClose } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from '../../route-paths'

interface Props {
  onClose: () => void
}

export const UpgradeModal: React.FC<Props> = ({ onClose }) => {
  const navigate = useNavigate()

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      onClose()
    }
  }

  return (
    <>
      <div
        className="fixed z-20 w-full h-full overflow-scroll transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-95 top-1/2 left-1/2"
        onKeyDown={handleKeypress}>
        <p
          className="absolute text-sm font-bold text-white top-3 hover:cursor-pointer right-4"
          onClick={() => onClose()}>
          <IoClose size={20} />
        </p>
        <div className="flex flex-col justify-center px-20 py-10 pt-20 md:h-full md:pt-10">
          <div className="self-center w-full lg:w-2/3 3xl:w-1/2">
            <p className="text-3xl font-bold text-neutral-200">
              Access Pass Required
            </p>

            <p className="mt-4 text-neutral-200">
              In order to add more wallets, your Main Wallet needs to hold a{' '}
              <strong>Poki Access Pass</strong>. The following collections
              qualify as Poki Access Pass:
            </p>

            <div className="flex flex-col gap-5 mt-8 2xl:w-2/3 md:flex-row">
              <div className="flex flex-col border rounded-b-lg w-72 border-neutral-800">
                <video autoPlay muted={true} loop playsInline>
                  <source
                    src="/images/poki_lifetime_pass.mp4"
                    type="video/mp4"
                  />
                </video>

                <div className="flex flex-col gap-4 p-5">
                  <p className="text-xl font-bold tracking-wider text-neutral-200">
                    Poki Lifetime Pass
                  </p>

                  <p className="break-words text-neutral-400">
                    The Poki Lifetime Pass is a limited supply utility token
                    that allows you to unlock the wallet limit on your
                    Portfolio, view your Portfolio History and more.
                  </p>

                  <button
                    onClick={() => navigate(RoutePaths.LifetimePass)}
                    className="self-start px-10 py-2 text-sm font-bold text-center text-white bg-blue-600 rounded-lg hover:bg-blue-500">
                    Get Lifetime Pass
                  </button>
                </div>
              </div>
            </div>

            <button
              className="self-center w-3/4 px-12 py-3 mt-12 rounded-lg bg-neutral-900 hover:bg-neutral-700 lg:w-1/3 3xl:w-1/4"
              onClick={onClose}>
              <p className="text-xs font-bold text-white">Close</p>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
