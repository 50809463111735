/* eslint-disable camelcase */

export interface User {
  token?: string
  id?: number
  pro?: boolean
  stake_addr?: string
  accounts?: Account[]
  discord_id?: string
}

export interface Account {
  id: number
  stake_addr: string
  name: string
}

export interface UserResponse {
  user?: User
  error?: string
}

export interface WalletResponse {
  accounts?: Account[]
  error?: string
}

export interface ChangeCollection {
  total: number
  percentage: number
}

export interface Change {
  [key: string]: number
  total_combined: number
  percentage_combined: number
  total_ada: number
  percentage_ada: number
  total_nfts: number
  percentage_nfts: number
}

export interface Share {
  id: string
  label: string
  value: string
}

export interface AnalyticsEntity {
  [key: string]: Collection[] | number | Share[]
  biggest_gainers: Collection[]
  biggest_decliners: Collection[]
  biggest_gainers_tokens: Collection[]
  biggest_decliners_tokens: Collection[]
  biggest_gainers_combined: Collection[]
  biggest_decliners_combined: Collection[]
  zombie_collections: Collection[]
  trending_collections: Collection[]
  diversification_score: number
  liquidity_score: number
  volatility_score: number
  share_tokens: Share[]
  share_nfts: Share[]
}

export interface AnalyticsResponse {
  analytics?: AnalyticsEntity
  error?: string
}

export interface Collection {
  [key: string]: string | number
  name: string
  id: number
  image: string
  count: number
  price: number
  value: number
  change_total: number
  change_percentage: number
  share: number
  share_category: number
  type: string
  floor_thickness: number
}

export interface HistoryEntity {
  [key: string]: number | string
  key: string
  value_total: number
  value_ada: number
  value_nfts: number
}

export interface CollectionHistoryEntity {
  [key: string]: number | string
  key: string
  value: number
}

export interface PortfolioEntity {
  [key: string]:
    | HistoryEntity[]
    | Collection[]
    | number
    | boolean
    | Change
    | string[]
  history: HistoryEntity[]
  sync_progress: number
  value_total: number
  value_ada: number
  value_nfts: number
  value_coins: number
  tick_values: string[]
  value_jpg_listings: number
  jpg_listings_count: number
  change: Change
  tokens: Collection[]
  collections: Collection[]
  history_synced: boolean
  biggest_gainers: Collection[]
  biggest_decliners: Collection[]
}

export interface PortfolioResponse {
  portfolio?: PortfolioEntity
  error?: string
}

export interface HoldingsEntity {
  value: number
  count: number
  share: number
  others: number
}

export interface CollectionStats {
  floor: number
  change: ChangeCollection
  history: CollectionHistoryEntity[]
  holdings: HoldingsEntity
  floor_thickness: number
  sales_count: number
  sales_volume: number
  unique_buyers: number
  alerts: AlertEntity[]
}

export interface TokenStats {
  price: number
  change: ChangeCollection
  history: CollectionHistoryEntity[]
}

export interface CollectionCombined {
  name: string
  jpg_store_name: string
  image: string
  stats: CollectionStats
}

export interface TokenEntity {
  name: string
  policy: string
  display_name: string
  image: string
  stats: TokenStats
}

export interface CollectionResponse {
  collection?: CollectionCombined
  error?: string
}

export interface TokenResponse {
  token?: TokenEntity
  error?: string
}

export interface MetadataEntity {
  floor_change: number
  price: number
  sales_count: number
  value: number
}

export interface AlertEntity {
  live_floor: number
  id: number
  metadata: MetadataEntity
  current_floor: number
  created_at: string
  alert_type: string
  project: Collection
}

export interface TopSellingEntity extends Collection {
  volume: number
  sales_count: number
  unique_buyers_count: number
  current_floor: number
}

export interface CnftAlertsResponse {
  cnft_alerts: AlertEntity[]
  cnft_sales: TopSellingEntity[]
  cnft_sales_volume: TopSellingEntity[]
}

export const API_BASE_URL = process.env.REACT_APP_API_URL

export default function useApi() {
  const requestWithBody = async (
    method: string,
    path: string,
    data: object = {},
    authToken = '',
  ) => {
    const res = await fetch(`${API_BASE_URL}${path}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
      body: JSON.stringify(data),
    })

    return res.json()
  }

  const requestWithParams = async (
    method: string,
    path: string,
    params: string,
    authToken = '',
  ) => {
    const res = await fetch(`${API_BASE_URL}${path}${params}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
    })

    return res.json()
  }

  const getOrCreateUser = (address: string): Promise<UserResponse> =>
    requestWithBody('POST', '/api/v1/poki/users/with_address', {
      wallet_address: address,
    })

  const getUser = (id: number, token: string): Promise<UserResponse> =>
    requestWithParams('GET', `/api/v1/poki/users/${id}`, '', token)

  const addWallet = (
    address: string,
    name: string,
    token: string,
  ): Promise<WalletResponse> =>
    requestWithBody(
      'POST',
      '/api/v1/poki/wallets',
      {
        wallet_address: address,
        wallet_name: name,
      },
      token,
    )

  const deleteWallet = (id: number, token: string): Promise<WalletResponse> =>
    requestWithParams('DELETE', `/api/v1/poki/wallets/${id}`, '', token)

  const getPortfolio = (
    interval: string,
    token: string,
  ): Promise<PortfolioResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/portfolios`,
      `?interval=${interval}`,
      token,
    )

  const getAnalytics = (
    interval: string,
    token: string,
  ): Promise<AnalyticsResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/portfolios/analytics`,
      `?interval=${interval}`,
      token,
    )

  const getCollection = (
    id: string,
    interval: string,
  ): Promise<CollectionResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/collections/${id}`,
      `?interval=${interval}`,
      '',
    )

  const getToken = (id: string, interval: string): Promise<TokenResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/tokens/${id}`,
      `?interval=${interval}`,
      '',
    )

  const getCnftAlerts = (): Promise<CnftAlertsResponse> =>
    requestWithParams('GET', `/api/v1/cnft_alerts`, '', '')

  const updateUser = (
    userId: number,
    discordId: string,
    token: string,
  ): Promise<UserResponse> =>
    requestWithBody(
      'PUT',
      `/api/v1/poki/users/${userId}`,
      {
        discord_id: discordId,
      },
      token,
    )

  return {
    getOrCreateUser,
    getUser,
    addWallet,
    deleteWallet,
    getPortfolio,
    getCollection,
    getCnftAlerts,
    updateUser,
    getAnalytics,
    getToken,
  }
}
