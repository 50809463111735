import React, { useState } from 'react'
import {
  IoClose,
  IoMenu,
  IoPieChartSharp,
  IoWalletSharp,
  IoBarChartSharp,
} from 'react-icons/io5'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaDiscord, FaRoad, FaTwitter } from 'react-icons/fa'
import { BsBroadcast } from 'react-icons/bs'
import { useUser } from '../../context/UserContext'
import { RoutePaths } from '../../route-paths'

export const Header: React.FC = () => {
  const [showMobileNav, setShowMobileNav] = useState(false)
  const { user, setUser } = useUser()
  const navigate = useNavigate()
  const location = useLocation()

  const onLogout = () => setUser({})
  const onClickBars = () => setShowMobileNav(!showMobileNav)

  const isCurrentLocation = (path: string) => path === location.pathname

  return (
    <>
      <div className="fixed top-0 left-0 z-20 justify-between w-full p-5 tracking-wider bg-neutral-900 bg-opacity-95">
        <div className="flex-row justify-between hidden w-full lg:flex">
          <div className="flex flex-row self-center gap-6">
            <img
              className="self-center w-10 hover:cursor-pointer"
              onClick={() => navigate(RoutePaths.Root)}
              src="/images/logo_small.png"
            />

            <div
              className={`self-center flex ml-5 flex-row gap-2 text-xs font-bold hover:cursor-pointer hover:text-white
              ${
                isCurrentLocation('/portfolio')
                  ? 'underline text-white'
                  : 'text-neutral-200'
              }`}
              onClick={() => navigate(RoutePaths.Portfolio)}>
              <IoPieChartSharp size={18} />
              <p className="self-center">PORTFOLIO</p>
            </div>

            {user?.token && (
              <div
                className={`self-center flex justify-center flex-row gap-2 text-xs font-bold hover:cursor-pointer
                ${
                  isCurrentLocation('/analytics')
                    ? 'underline text-white'
                    : 'text-neutral-200'
                }`}
                onClick={() => navigate(RoutePaths.Analytics)}>
                <IoBarChartSharp size={18} className="self-center" />
                <p className="self-center">ANALYTICS</p>
              </div>
            )}

            {user?.token && (
              <div
                className={`self-center flex justify-center flex-row gap-2 text-xs font-bold hover:cursor-pointer
                ${
                  isCurrentLocation('/account-overview')
                    ? 'underline text-white'
                    : 'text-neutral-200'
                }`}
                onClick={() => navigate(RoutePaths.AccountOverview)}>
                <IoWalletSharp size={18} className="self-center" />
                <p className="self-center">ACCOUNT</p>
              </div>
            )}

            <div
              className={`self-center flex justify-center flex-row gap-2 text-xs font-bold hover:cursor-pointer
              ${
                isCurrentLocation('/live-market')
                  ? 'underline text-white'
                  : 'text-neutral-200'
              }`}
              onClick={() => navigate(RoutePaths.LiveMarket)}>
              <BsBroadcast size={18} className="self-center" />
              <p className="self-center">MARKET</p>
            </div>

            <div
              className={`self-center flex justify-center flex-row gap-2 text-xs font-bold hover:cursor-pointer
              ${
                isCurrentLocation('/roadmap')
                  ? 'underline text-white'
                  : 'text-neutral-200'
              }`}
              onClick={() => navigate(RoutePaths.Roadmap)}>
              <FaRoad size={18} className="self-center" />
              <p className="self-center">ROADMAP</p>
            </div>
          </div>

          <div className="flex flex-row gap-8">
            <a
              href="https://discord.gg/qPAp3eXs7X"
              rel="noreferrer"
              target="_blank"
              className="self-center text-indigo-600">
              <FaDiscord size={30} />
            </a>

            <a
              href="https://twitter.com/poki_portfolio"
              rel="noreferrer"
              target="_blank"
              className="self-center text-blue-500">
              <FaTwitter size={30} />
            </a>

            {user.token ? (
              <button
                className="self-center px-5 py-2 text-xs font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                onClick={onLogout}>
                Logout
              </button>
            ) : (
              <button
                className="self-center px-5 py-3 text-xs font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                onClick={() => navigate(RoutePaths.ConnectWallet)}>
                Connect Wallet
              </button>
            )}
          </div>
        </div>

        {!showMobileNav && (
          <div className="flex flex-row justify-between lg:hidden">
            <img
              className="w-10 hover:cursor-pointer"
              onClick={() => navigate(RoutePaths.Root)}
              src="/images/logo_small.png"
            />
            <p
              className="self-center text-white hover:cursor-pointer"
              onClick={onClickBars}>
              <IoMenu size={22} />
            </p>
          </div>
        )}

        {showMobileNav && (
          <div className="fixed top-0 left-0 w-full h-screen overflow-hidden text-neutral-200 bg-neutral-900">
            <p
              className="absolute text-white top-5 hover:cursor-pointer right-5 lg:hidden"
              onClick={onClickBars}>
              <IoClose size={20} />
            </p>

            <div className="flex flex-col w-full h-screen gap-10 px-10 pt-10 text-lg uppercase">
              <div className="pb-5 border-b border-neutral-800">
                <img
                  className="w-10 hover:cursor-pointer"
                  onClick={() => navigate(RoutePaths.Root)}
                  src="/images/logo_small.png"
                />
              </div>

              <div
                className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer hover:text-white
                  ${
                    isCurrentLocation('/')
                      ? 'underline text-white'
                      : 'text-neutral-200'
                  }`}
                onClick={() => {
                  onClickBars()
                  navigate(RoutePaths.Root)
                }}>
                <p>HOME</p>
              </div>

              <div
                className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer hover:text-white
                  ${
                    isCurrentLocation('/portfolio')
                      ? 'underline text-white'
                      : 'text-neutral-200'
                  }`}
                onClick={() => {
                  onClickBars()
                  navigate(RoutePaths.Portfolio)
                }}>
                <IoPieChartSharp size={18} />
                <p>PORTFOLIO</p>
              </div>

              {user?.token && (
                <div
                  className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer
                    ${
                      isCurrentLocation('/analytics')
                        ? 'underline text-white'
                        : 'text-neutral-200'
                    }`}
                  onClick={() => {
                    onClickBars()
                    navigate(RoutePaths.Analytics)
                  }}>
                  <IoBarChartSharp size={18} className="" />
                  <p>Analytics</p>
                </div>
              )}

              {user?.token && (
                <div
                  className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer
                    ${
                      isCurrentLocation('/account-overview')
                        ? 'underline text-white'
                        : 'text-neutral-200'
                    }`}
                  onClick={() => {
                    onClickBars()
                    navigate(RoutePaths.AccountOverview)
                  }}>
                  <IoWalletSharp size={18} className="" />
                  <p>ACCOUNT OVERVIEW</p>
                </div>
              )}

              <div
                className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer
                ${
                  isCurrentLocation('/live-market')
                    ? 'underline text-white'
                    : 'text-neutral-200'
                }`}
                onClick={() => {
                  onClickBars()
                  navigate(RoutePaths.LiveMarket)
                }}>
                <BsBroadcast size={18} className="self-center" />
                <p className="self-center">MARKET</p>
              </div>

              <div
                className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer
                ${
                  isCurrentLocation('/roadmap')
                    ? 'underline text-white'
                    : 'text-neutral-200'
                }`}
                onClick={() => {
                  onClickBars()
                  navigate(RoutePaths.Roadmap)
                }}>
                <FaRoad size={18} className="self-center" />
                <p className="self-center">ROADMAP</p>
              </div>

              <div className="flex flex-row gap-8">
                <a
                  href="https://discord.gg/qPAp3eXs7X"
                  rel="noreferrer"
                  target="_blank"
                  className="self-center text-indigo-600">
                  <FaDiscord size={30} />
                </a>

                <a
                  href="https://twitter.com/poki_portfolio"
                  rel="noreferrer"
                  target="_blank"
                  className="self-center text-blue-500">
                  <FaTwitter size={30} />
                </a>
              </div>
              {user.token ? (
                <button
                  className="self-center w-full px-5 py-3 mt-10 text-sm font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                  onClick={() => {
                    onClickBars()
                    onLogout()
                  }}>
                  Logout
                </button>
              ) : (
                <button
                  className="self-center w-full px-5 py-3 mt-10 text-sm font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                  onClick={() => {
                    onClickBars()
                    navigate(RoutePaths.ConnectWallet)
                  }}>
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
