export enum RoutePaths {
  AccountOverview = '/account-overview',
  Analytics = '/analytics',
  Collection = '/collections/:id',
  ConnectWallet = '/connect-wallet',
  Contact = '/contact',
  DiscordOauth = '/discord-oauth',
  LifetimePass = '/lifetime-pass',
  LiveMarket = '/live-market',
  MagicLogin = '/magic-login',
  Portfolio = '/portfolio',
  Privacy = '/privacy',
  Roadmap = '/roadmap',
  Root = '/',
  Token = '/tokens/:id',
}
