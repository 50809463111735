import React from 'react'

export const Contact: React.FC = () => {
  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 xl:w-2/3 3xl:w-1/2">
          <p className="text-6xl font-bold text-neutral-200">Contact</p>

          <p className="mt-12 text-sm text-neutral-200">
            For questions or support requests please contact us via Email.
          </p>

          <a
            href="mailto: info@cnft-alerts.com"
            className="mt-4 font-bold text-white">
            info@cnft-alerts.com
          </a>

          <div className="flex flex-col justify-center pt-10 mt-32 border-t border-neutral-800">
            <div className="flex flex-row self-center gap-4">
              <p className="self-center text-xs font-bold text-neutral-200">
                Powered by
              </p>
              <img src="/images/cnft_alerts_logo.jpg" className="w-16" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
