import { createContext, useContext, Dispatch, SetStateAction } from 'react'
import { User } from '../../hooks/useApi'

interface UserState {
  user: User
  setUser: Dispatch<SetStateAction<User>>
}

const UserContext = createContext({} as UserState)

export const useUser = () => useContext(UserContext)

export default UserContext
