import React, { useState, useEffect } from 'react'
import TimeAgo from 'javascript-time-ago'
import useApi, { AlertEntity, TopSellingEntity } from '../../hooks/useApi'
import {
  ADA_SYMBOL,
  intToString,
  numberColor,
  numberWithSign,
  REASON_COLORS,
} from '../../util/format'
import { useNavigate } from 'react-router-dom'

const WIDTHS = [
  'w-full',
  'w-10/12',
  'w-9/12',
  'w-8/12',
  'w-7/12',
  'w-6/12',
  'w-5/12',
  'w-4/12',
  'w-3/12',
  'w-2/12',
]
const BG_COLORS = [
  'bg-blue-600',
  'bg-blue-500',
  'bg-blue-400',
  'bg-blue-300',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
]

export const LiveMarket = () => {
  const [cnftAlerts, setCnftAlerts] = useState([] as AlertEntity[])
  const [topSelling, setTopSelling] = useState([] as TopSellingEntity[])
  const [topSellingByVolume, setTopSellingByVolume] = useState(
    [] as TopSellingEntity[],
  )
  const [, setCnftAlertsLoading] = useState(false)
  const [topSellingData, setTopSellingData] = useState([] as TopSellingEntity[])
  const [displaySalesByVolume, setDisplaySalesByVolume] = useState(false)

  const { getCnftAlerts } = useApi()
  const navigate = useNavigate()

  const timeAgo = new TimeAgo('en-US')

  const fetchCnftAlerts = async () => {
    try {
      const {
        cnft_alerts: alerts,
        cnft_sales: sales,
        cnft_sales_volume: salesByVolume,
      } = await getCnftAlerts()
      setCnftAlerts(alerts || [])
      setTopSelling(sales || [])
      if (displaySalesByVolume) {
        setTopSellingData(salesByVolume || [])
      } else {
        setTopSellingData(sales || [])
      }
      setTopSellingByVolume(salesByVolume || [])
      setCnftAlertsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchCnftAlerts()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchCnftAlerts()
    }, 5000)

    return () => clearInterval(interval)
  }, [displaySalesByVolume])

  const getTimeAgo = (createdAt: string) => {
    return timeAgo.format(new Date(createdAt))
  }

  return (
    <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
      <div className="flex flex-col w-full gap-2 p-10 pt-32 pb-20 2xl:w-5/6 text-neutral-200">
        <p className="text-6xl font-bold text-neutral-200">Live Market</p>

        <p className="text-xs text-neutral-200">
          Live Market Data is updated automatically every minute.
        </p>

        <div className="flex flex-col w-full gap-8 mt-8 md:flex-row">
          <div className="flex flex-col w-full gap-4 md:w-1/2">
            <div className="mb-4">
              <p className="text-lg font-semibold tracking-wide text-neutral-400">
                Top Selling Projects (1h) 📈
              </p>
              <div className="flex flex-row gap-3 mt-2 text-xs text-blue-400">
                <p
                  onClick={() => {
                    setTopSellingData(topSelling)
                    setDisplaySalesByVolume(false)
                  }}
                  className={`${
                    displaySalesByVolume ? '' : 'font-bold underline'
                  } cursor-pointer`}>
                  TOTAL SALES
                </p>
                <p>|</p>
                <p
                  onClick={() => {
                    setTopSellingData(topSellingByVolume)
                    setDisplaySalesByVolume(true)
                  }}
                  className={`${
                    displaySalesByVolume ? 'font-bold underline' : ''
                  } cursor-pointer`}>
                  TOTAL VOLUME
                </p>
              </div>
            </div>
            {topSellingData.length !== 10 && (
              <div className="relative flex flex-row justify-center gap-3 p-5 text-xs font-semibold border border-stone-800 bg-stone-900 rounded-xl text-neutral-300 md:h-40">
                <p className="self-center text-neutral-400">
                  Top Selling Data is currently incomplete or not available. We
                  are investigating a solution.
                </p>
              </div>
            )}
            {topSellingData.map((collection, index) => {
              return (
                <div
                  className={`flex flex-row w-full text-xs font-semibold text-neutral-200 rounded-xl hover:cursor-pointer hover:scale-105 bg-stone-900 border-stone-800 border`}
                  key={`top-selling-${index}`}
                  onClick={() => navigate(`/collections/${collection.id}`)}>
                  <img
                    src={collection.image}
                    className="self-center object-cover w-16 h-full rounded-l-lg"
                  />

                  <div className="flex flex-col w-full px-3 pt-1 pb-2 text-neutral-200">
                    <p className="text-sm font-bold leading-6 tracking-wide text-neutral-200">
                      {collection.display_name}
                    </p>

                    <div className="flex flex-row gap-2 mt-1 md:gap-4">
                      {displaySalesByVolume ? (
                        <div className="flex flex-row gap-1 px-2 py-1 rounded-lg bg-neutral-800">
                          <p className="self-center text-xs text-neutral-400">
                            Vol.:
                          </p>
                          <p className="self-center text-xs font-bold">
                            {intToString(collection.volume)}
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-row gap-1 px-2 py-1 rounded-lg bg-neutral-800">
                          <p className="self-center text-xs text-neutral-400">
                            Sales:
                          </p>
                          <p className="self-center text-xs font-bold">
                            {collection.sales_count}
                          </p>
                        </div>
                      )}

                      <div className="flex flex-row gap-1 px-2 py-1 rounded-lg bg-neutral-800">
                        <p className="self-center text-xs text-neutral-400">
                          Buyers:
                        </p>
                        <p className="self-center text-xs font-bold">
                          {collection.unique_buyers_count}
                        </p>
                      </div>

                      <div className="flex flex-row gap-1 px-2 py-1 rounded-lg bg-neutral-800">
                        <p className="self-center text-xs text-neutral-400">
                          Floor:
                        </p>
                        <p className="self-center text-xs font-bold">
                          {intToString(collection.current_floor)}
                        </p>
                      </div>
                    </div>

                    <div
                      className={`h-3 ${BG_COLORS[index]} ${WIDTHS[index]} mt-2 rounded-r-lg`}
                    />
                  </div>
                </div>
              )
            })}
          </div>

          <div className="flex flex-col w-full gap-4 md:w-1/2">
            <p className="mb-2 text-lg font-bold tracking-wide md:mb-10 text-neutral-500">
              Active Alerts <span className="animate-pulse">🚨</span>
            </p>

            {cnftAlerts.length === 0 && (
              <div className="relative flex flex-row justify-center gap-3 p-5 text-xs font-semibold border border-stone-800 bg-stone-900 rounded-xl text-neutral-300 md:h-40">
                <p className="self-center text-neutral-400">
                  There are currently no active alerts.
                </p>
              </div>
            )}

            {cnftAlerts.map((alert, index) => {
              return (
                <div
                  className="relative flex flex-row gap-3 p-3 text-xs font-semibold border border-stone-800 bg-stone-900 rounded-xl text-neutral-300 hover:cursor-pointer hover:scale-105"
                  key={`alert-${index}`}
                  onClick={() => navigate(`/collections/${alert.project.id}`)}>
                  <img
                    src={alert.project.image}
                    className="object-cover w-20 h-auto"
                  />
                  <div className="flex flex-col">
                    <p className="text-sm font-bold leading-6 tracking-wide text-white">
                      {alert.project.display_name}
                    </p>

                    <p className="font-normal text-neutral-500">
                      <span
                        className={`font-bold capitalize ${
                          REASON_COLORS[alert.alert_type]
                        }`}>
                        {alert.alert_type}
                      </span>{' '}
                      - {getTimeAgo(alert.created_at)}
                    </p>

                    <div className="mt-3">
                      {alert.alert_type === 'high sale' && (
                        <p>
                          Sold for: {alert.metadata.price} {ADA_SYMBOL}
                        </p>
                      )}

                      {alert.alert_type === 'floor change' && (
                        <p>
                          Change:{' '}
                          <span
                            className={`${numberColor(
                              alert.metadata.floor_change,
                            )}`}>
                            {numberWithSign(alert.metadata.floor_change)}%
                          </span>
                        </p>
                      )}

                      {alert.alert_type === 'sales volume' && (
                        <p>Sales count: {alert.metadata.sales_count}</p>
                      )}

                      {alert.alert_type === 'thin floor' && (
                        <p>Listings to 2x Floor: {alert.metadata.value}</p>
                      )}
                    </div>

                    <div className="mt-1">
                      <p>
                        Floor during Alert: {alert.current_floor} {ADA_SYMBOL}
                      </p>
                      <p className="mt-1">
                        Current Floor: {alert.live_floor} {ADA_SYMBOL}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className="flex flex-col justify-center pt-10 mt-32 border-t border-neutral-800">
          <div className="flex flex-row self-center gap-4">
            <p className="self-center text-xs font-bold text-neutral-200">
              Powered by
            </p>
            <img src="/images/cnft_alerts_logo.jpg" className="w-16" />
          </div>
        </div>
      </div>
    </div>
  )
}
