import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../context/UserContext'
import useApi from '../../hooks/useApi'
import { RoutePaths } from '../../route-paths'

export const DiscordOauth = () => {
  const { updateUser } = useApi()
  const { user, setUser } = useUser()
  const navigate = useNavigate()

  const updateUserAndRedirect = async (discordId: string) => {
    if (user.id && user.token) {
      const response = await updateUser(user.id, discordId, user.token)

      if (response.user) {
        setUser(response.user)
        navigate(RoutePaths.AccountOverview)
      }
    }
  }

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1))
    const [accessToken, tokenType] = [
      fragment.get('access_token'),
      fragment.get('token_type'),
    ]

    if (accessToken) {
      fetch('https://discord.com/api/users/@me', {
        headers: {
          authorization: `${tokenType} ${accessToken}`,
        },
      })
        .then((result) => result.json())
        .then((response) => {
          const { id } = response
          updateUserAndRedirect(id)
        })
        .catch(console.error)
    }
  }, [])

  return (
    <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
      <div className="flex flex-col w-full gap-2 p-10 pt-32 pb-20 lg:w-2/3 3xl:w-1/2 text-neutral-200">
        <strong className="mb-4 text-3xl">Connecting Discord ...</strong>
      </div>
    </div>
  )
}
