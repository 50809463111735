import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext'
import useApi, { TokenEntity } from '../../hooks/useApi'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import {
  ADA_SYMBOL,
  INTERVALS,
  formatDate,
  numberColor,
  numberWithSign,
  percentageDiff,
} from '../../util/format'
import { LineChart } from '../../components/LineChart'

type ValueFromChart = number | null
type DateFromChart = string | null

export const Token: React.FC = () => {
  const [token, setToken] = useState({} as TokenEntity)
  const [isLoading, setLoading] = useState(true)
  const [selectedInterval, setSelectedInterval] = useState('24h')
  const [historyLoading, setHistoryLoading] = useState(false)
  const [valueFromChart, setValueFromChart] = useState(null as ValueFromChart)
  const [dateFromChart, setDateFromChart] = useState(null as DateFromChart)

  const { id } = useParams()
  const { getToken } = useApi()

  const fetchToken = async (interval: string) => {
    if (id) {
      try {
        const result = await getToken(id, interval)
        if (result.token) {
          setToken(result.token)
          setLoading(false)
        } else {
          console.log('An unknown error occured')
        }
      } catch (error) {
        console.log('An unknown error occured')
      }
    }
  }

  useEffect(() => {
    fetchToken(selectedInterval)
  }, [])

  const onIntervalClicked = async (interval: string) => {
    if (historyLoading) {
      return
    }

    setSelectedInterval(interval)
    setHistoryLoading(true)
    await fetchToken(interval)
    setHistoryLoading(false)
  }

  const tokenHistory = () => {
    return token.stats.history.map((element) => {
      return {
        x: element.key,
        y: element.value,
      }
    })
  }

  const yScaleMinMax = () => {
    const history = tokenHistory()
    const minObject = history.reduce(function (prev, current) {
      return prev.y < current.y ? prev : current
    })
    const maxObject = history.reduce(function (prev, current) {
      return prev.y > current.y ? prev : current
    })

    const minValue = minObject.y
    const maxValue = maxObject.y

    return { min: minValue, max: maxValue }
  }

  const onMouseLeaveLineChart = () => {
    setValueFromChart(null)
    setDateFromChart(null)
  }

  const onMouseMoveLineChart = (point: { x: string; y: number }) => {
    setValueFromChart(point.y)
    setDateFromChart(point.x)
  }

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 lg:w-2/3 3xl:w-1/2">
          <div className="flex flex-row gap-2 mt-10 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <div className="flex flex-row gap-10">
            <div className="flex flex-col w-full">
              <div className="flex flex-row gap-4">
                {token.image ? (
                  <img
                    src={token.image}
                    className="self-center rounded-lg w-14 h-14"
                  />
                ) : (
                  <div className="flex self-center justify-center rounded-full w-14 h-14 bg-neutral-800">
                    <p className="self-center text-2xl font-normal text-neutral-200">
                      ?
                    </p>
                  </div>
                )}
                <p className="text-6xl font-bold text-neutral-200">
                  {token.display_name}
                </p>
              </div>

              <a
                href={`https://app.minswap.org/swap?currencySymbolA=&tokenNameA=&currencySymbolB=${token.policy}&tokenNameB=${token.name}`}
                target="_blank"
                rel="noreferrer"
                className="self-start px-3 py-2 mt-4 text-sm font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-400 hover:cursor-pointer">
                View on Minswap
              </a>

              <div className="flex flex-col p-6 mt-8 border rounded-lg border-neutral-800 bg-stone-900">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col w-full">
                    <p className="font-bold text-neutral-400">Price</p>
                    <p className="text-3xl font-bold text-neutral-200">
                      {valueFromChart !== null
                        ? `${valueFromChart} ${ADA_SYMBOL}`
                        : `${token.stats.price} ${ADA_SYMBOL}`}
                    </p>

                    <p className="text-sm font-semibold text-neutral-400">
                      {dateFromChart !== null
                        ? formatDate(dateFromChart, selectedInterval)
                        : formatDate(new Date().toString(), selectedInterval)}
                    </p>

                    {valueFromChart !== null ? (
                      <div
                        className={`font-bold ${numberColor(
                          percentageDiff(tokenHistory()[0].y, valueFromChart),
                        )}`}>
                        {numberWithSign(
                          valueFromChart - tokenHistory()[0].y,
                          6,
                        )}{' '}
                        ₳ (
                        {numberWithSign(
                          percentageDiff(tokenHistory()[0].y, valueFromChart),
                        )}
                        %)
                      </div>
                    ) : (
                      <div
                        className={`font-bold ${numberColor(
                          token.stats.change.percentage,
                        )}`}>
                        {numberWithSign(token.stats.change.total, 6)} ₳ (
                        {token.stats.change.percentage}%)
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className="flex flex-row gap-2">
                      {INTERVALS.map((intervalData, index) => {
                        return (
                          <p
                            key={`total-interval-${index}`}
                            className={`px-3 py-1 text-xs font-semibold
                            bg-gray-700 rounded-lg cursor-pointer
                            hover:bg-gray-500 text-neutral-200
                            ${
                              intervalData.key === selectedInterval
                                ? 'bg-gray-500'
                                : ''
                            }`}
                            onClick={() => onIntervalClicked(intervalData.key)}>
                            {intervalData.display}
                          </p>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className="h-20 mt-12 lg:h-36">
                  {historyLoading ? (
                    <div className="flex flex-row justify-center gap-2">
                      <BounceLoader
                        className="self-center"
                        color={'#ffffff'}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />

                      <p className="self-center text-lg font-bold text-neutral-400">
                        Loading
                      </p>
                    </div>
                  ) : (
                    <LineChart
                      data={tokenHistory()}
                      yScaleMinMax={yScaleMinMax()}
                      onMouseLeave={onMouseLeaveLineChart}
                      onMouseMove={onMouseMoveLineChart}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
