import React, { useState } from 'react'
import { IoClose, IoOpenOutline } from 'react-icons/io5'
import { BounceLoader } from 'react-spinners'
import { useUser } from '../../context/UserContext'
import useApi from '../../hooks/useApi'

interface Props {
  stake_addr: string
  id: number
  name: string
  onClose: () => void
}

export const RemoveWalletModal: React.FC<Props> = ({
  onClose,
  id,
  name,
  stake_addr,
}) => {
  const [apiError, setApiError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const { deleteWallet } = useApi()
  const { user, setUser } = useUser()

  const removeWallet = async () => {
    if (user.token) {
      try {
        setSubmitting(true)
        const response = await deleteWallet(id, user.token)

        if (response.error) {
          setApiError(response.error)
        } else if (response.accounts) {
          setUser({ ...user, ...{ accounts: response.accounts } })
          setApiError('')
          onClose()
        } else {
          setApiError('An unknown Error occured.')
        }
      } catch (error) {
        console.log(error)
        setApiError('An unknown Error occured.')
      } finally {
        setSubmitting(false)
      }
    }
  }

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      removeWallet()
    } else if (e.key === 'Escape') {
      onClose()
    }
  }

  return (
    <>
      <div
        className="fixed z-20 w-full h-full transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-95 top-1/2 left-1/2"
        onKeyDown={handleKeypress}>
        <p
          className="absolute text-sm font-bold text-white top-3 hover:cursor-pointer right-4"
          onClick={() => onClose()}>
          <IoClose size={20} />
        </p>
        <div className="flex flex-col justify-center w-full h-full px-20 py-10">
          <div className="self-center w-full lg:w-2/3 3xl:w-1/2">
            <p className="text-6xl font-bold text-neutral-200">Remove Wallet</p>

            <p className="mt-10 text-xl font-bold text-neutral-600">
              Wallet Address
            </p>
            <div className="flex flex-row text-neutral-200 hover:text-white">
              <a
                href={`https://pool.pm/${stake_addr}`}
                target="_blank"
                rel="noreferrer"
                className="truncate">
                {stake_addr}
              </a>
              <a
                href={`https://pool.pm/${stake_addr}`}
                target="_blank"
                rel="noreferrer"
                className="self-center mb-1 ml-1">
                <IoOpenOutline size={17} />
              </a>
            </div>

            <p className="mt-10 text-xl font-bold text-neutral-600">
              Wallet Name
            </p>
            <p className="mt-1 font-bold text-neutral-200">{name}</p>

            <div className="flex flex-row gap-5">
              <button
                autoFocus
                className={`self-center w-3/4 px-12 py-3 mt-12 ${
                  isSubmitting ? 'bg-red-400' : 'bg-red-500'
                } rounded-lg hover:bg-red-400 lg:w-1/3 3xl:w-1/4`}
                onClick={removeWallet}
                disabled={isSubmitting}>
                <div className="flex flex-row justify-center gap-2">
                  {isSubmitting && (
                    <BounceLoader
                      color={'#ffffff'}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                  <p className="self-center text-xs font-bold text-white">
                    Yes
                  </p>
                </div>
              </button>

              <button
                className="self-center w-3/4 px-12 py-3 mt-12 rounded-lg bg-neutral-900 hover:bg-neutral-700 lg:w-1/3 3xl:w-1/4"
                onClick={onClose}>
                <p className="text-xs font-bold text-white">No</p>
              </button>
            </div>

            {apiError && (
              <p className="mt-4 text-xs text-red-400">
                Oops, that didn&apos;t work. {apiError}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
