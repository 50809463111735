import { linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import React from 'react'

interface LineChartData {
  x: string
  y: number
}

interface Props {
  data: LineChartData[]
  yScaleMinMax: {
    min: number
    max: number
  }
  onMouseLeave: () => void
  onMouseMove: (point: LineChartData) => void
}

export const LineChart: React.FC<Props> = ({
  data,
  yScaleMinMax,
  onMouseLeave,
  onMouseMove,
}) => {
  return (
    <>
      <ResponsiveLine
        data={[
          {
            id: 'Line Chart',
            data: data,
          },
        ]}
        enableArea={true}
        defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
        yScale={{
          type: 'linear',
          min: yScaleMinMax.min * 0.95,
          max: yScaleMinMax.max * 1.05,
        }}
        yFormat=" >-.2f"
        curve="linear"
        colors={['#60a5fa']}
        crosshairType="x"
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        onMouseLeave={onMouseLeave}
        onMouseMove={(point) =>
          onMouseMove({ x: point.data.x as string, y: point.data.y as number })
        }
        margin={{ top: 10 }}
        theme={{
          crosshair: {
            line: {
              stroke: '#f9fafb',
              strokeWidth: 1,
              strokeOpacity: 0.35,
            },
          },
          axis: {
            legend: {
              text: {
                color: '#f9fafb',
              },
            },
          },
          tooltip: {
            container: {
              display: 'none',
            },
          },
        }}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        useMesh={true}
        legends={[]}
      />
    </>
  )
}
