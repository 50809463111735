import React, { useEffect, useState } from 'react'
import { RoutePaths } from '../../route-paths'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { BounceLoader } from 'react-spinners'
import { ADA_SYMBOL } from '../../util/format'

export const PricingBoxes = () => {
  const [floor, setFloor] = useState(0)

  useEffect(() => {
    const fetchFloor = async () => {
      const response = await fetch(
        'https://server.jpgstoreapis.com/collection/9fa8b41c2c7e8902a2b7b14bb957a2b39ff6a7a032fed0d4e687db5e/floor',
      )
      const { floor } = await response.json()

      if (floor) {
        setFloor(floor / 1000000)
      }
    }

    fetchFloor()
  }, [])
  return (
    <>
      <div className="flex flex-col w-full gap-8 mt-10 md:flex-row">
        <div className="flex flex-col w-full px-10 py-10 border rounded-xl md:w-1/2 bg-neutral-800 border-neutral-700">
          <p className="text-lg font-bold tracking-wide text-purple-600">
            Basic
          </p>

          <p className="mt-2 text-5xl font-bold text-neutral-200">
            Free{' '}
            <span className="self-center text-base font-semi-bold text-neutral-400">
              / until doomsday 💀
            </span>
          </p>

          <p className="mt-8 text-sm text-neutral-400 text-wide">
            Poki Basic is free of charge for everyone. While being a powerful
            addition to the toolbox of every degen, it comes with a limited set
            of features.
          </p>

          <div className="flex flex-col gap-6 p-5 mt-12 rounded-lg bg-neutral-700">
            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#16a34a"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Valuation of your Cardano NFTs
                </p>
                <p className="text-xs text-neutral-400">
                  Get the combined value of your assets. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#16a34a"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Token / Coin Tracking
                </p>
                <p className="text-xs text-neutral-400">
                  Track your coins like $meld and $clay. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#16a34a"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Portfolio Analytics
                </p>
                <p className="text-xs text-neutral-400">
                  Your biggest gainers / decliners at one glance. Status:
                  Available.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full px-10 py-10 border rounded-xl md:w-1/2 bg-neutral-800 border-neutral-700">
          <p className="text-lg font-bold tracking-wide text-purple-600">Pro</p>
          {floor == 0 ? (
            <div className="flex flex-row gap-2 mt-4">
              <BounceLoader
                color={'#ffffff'}
                loading={true}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p className="self-center font-bold text-neutral-200">
                Loading Price
              </p>
            </div>
          ) : (
            <p className="mt-2 text-5xl font-bold text-neutral-200">
              {floor} {ADA_SYMBOL}{' '}
              <span className="self-center text-base font-semi-bold text-neutral-400">
                / JPG.store
              </span>
            </p>
          )}

          <p className="mt-8 text-sm text-neutral-400 text-wide">
            Poki Pro is currently only unlockable through holding a{' '}
            <a className="text-purple-600" href={RoutePaths.LifetimePass}>
              Poki Lifetime Pass
            </a>
            . In the future there will be paid monthly / yearly subscriptions as
            well.
          </p>

          <div className="flex flex-col gap-6 p-5 mt-12 rounded-lg bg-neutral-700">
            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#16a34a"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Add Unlimited Wallets
                </p>
                <p className="text-xs text-neutral-400">
                  Unlock the 1-Wallet-Limit. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#16a34a"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Access Portfolio History
                </p>
                <p className="text-xs text-neutral-400">
                  See how your Portfolio performed over time. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#16a34a"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Portfolio includes listed Assets
                </p>
                <p className="text-xs text-neutral-400">
                  Track your jpg.store listings. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#737373"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Mobile App Push Notifications
                </p>
                <p className="text-xs text-neutral-400">
                  Price Alerts, Selling Recommendations, Whale Alerts, etc.
                  Status: Planned.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#737373"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Trait Floor Valuation
                </p>
                <p className="text-xs text-neutral-400">
                  Your portfolio includes valuations for your rare NFTs. Status:
                  Planned.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <IoCheckmarkSharp
                color="#737373"
                className="self-center"
                size={30}
              />
              <div className="flex flex-col self-center">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Portfolio includes staked Assets
                </p>
                <p className="text-xs text-neutral-400">
                  Track your staked Mutant or Yummi Universe NFTs. Status:
                  Planned.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
