import React, { useEffect } from 'react'

export const MagicLogin = () => {
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('token')

    window.location.assign(`pokiportfolio://magicLogin?token=${token}`)
  }, [])

  return (
    <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
      <div className="flex flex-col w-full gap-2 p-10 pt-32 pb-20 lg:w-2/3 3xl:w-1/2 text-neutral-200">
        <strong className="mb-4 text-3xl">Redirecting ...</strong>
      </div>
    </div>
  )
}
